import { useContext, useEffect, useRef, useState } from "react";
import CurrencyInput from 'react-currency-input-field';
import { Link } from "react-router-dom";
import { Avatar, Button, Checkbox, Col, DatePicker, Divider, FlexboxGrid, Form, InputGroup, Loader, MaskedInput, Panel, Radio, RadioGroup, SelectPicker, Stack, Tag, TagInput, Tooltip, Whisper, useToaster } from "rsuite";

import SelectSalvaFiltro from "../../components/SelectSalvaFiltro";
import SelectTabelasRFB from "../../components/SelectTabelasRFB";
import { showMessage } from "../../components/ShowMessage";

import { AuthContext } from "../../contexts/authContext";
import { EmpresaContext } from "../../contexts/empresaContext";

import { useApiCliente } from "../../hooks/useApiCliente";
import { useApiLeads } from '../../hooks/useApiLeads';
import { useApiRFBTabelas } from '../../hooks/useApiRFBTabelas';

import { mask } from '../../services/utils';

import './style.css';

const HelpTooltip = ({ text }) => (
    <Whisper placement="topStart" trigger="hover" speaker={<Tooltip>{text}</Tooltip>}>
        <Avatar size='xs' circle style={{ background: '#708090' }}>
            <span style={{ fontSize: 14 }}>?</span>
        </Avatar>
    </Whisper>
)

const LeadsConsultar = () => {
    const { empresaId, permiteTelaSelecaoLeads, saldoCredito, setEmpresaReadOnly } = useContext(EmpresaContext);
    const { user } = useContext(AuthContext)

    const defaultDados = {
        cnae: [],
        dataInicial: null,
        dataFinal: null,
        uf: [],
        cidade: [],
        socio: [],
        razaoSocial: [],
        cep: [],
        capitalSocial: [],
        opcaoMei: 'T',
        opcaoSimples: 'T',
        desconsiderarCliente: 'B',
        socioTipoBusca: 0,
        razaoSocialTipoBusca: 0,
        considerarRecuperacaoJudicial: 'N',
        considerarCnaeSecundaria: 'N',
        deveConterTodosCnaes: 'N',
        qualificacaoSocio: []
    };

    const tipoBusca = texto => ([
        { value: 0, label: `${texto} deve ser igual aos termos` },
        { value: 1, label: `${texto} conter todos os termos` },
        { value: 2, label: `${texto} conter pelo menos um dos termos` }
    ])

    const apiRFB = useApiRFBTabelas()
    const apiLeads = useApiLeads()
    const apiCliente = useApiCliente()

    const [step, setStep] = useState(1)
    const [waiting, setWaiting] = useState(false)
    const [downloading, setDownloading] = useState(false)

    const [idLeads, setIdLeads] = useState();
    const [csvFileName, setCsvFileName] = useState()

    const [leadsCount, setLeadsCount] = useState()
    const [novoSaldo, setNovoSaldo] = useState(0)

    const [disableButton, setDisableButton] = useState(false)
    const [disabledMunicipio, setDisabledMunicipio] = useState(true)
    const [disabledCEP, setDisabledCEP] = useState(false)

    const [totalClientes, setTotalClientes] = useState(0)
    const [totalClientesPorCnpjBasico, setTotalClientesPorCnpjBasico] = useState(0)

    const [dados, setDados] = useState(defaultDados)

    const getCountClientes = async () => {
        try {
            const data = await apiCliente.getCountClientes(empresaId)

            setTotalClientes(data.totalPorCnpj);
            setTotalClientesPorCnpjBasico(data.totalPorCnpjBasico);
        } catch (error) {
            showError(error)
        }
    }

    useEffect(() => {
        (async () => await getCountClientes())();

        // eslint-disable-next-line
    }, [empresaId])

    useEffect(() => {
        const dMun = dados.uf.length !== 1;
        setDisabledMunicipio(dMun);
        if (dMun) setDados({ ...dados, cidade: [] });

        // eslint-disable-next-line
    }, [dados.uf])

    useEffect(() => {
        const dCep = dados.uf.length > 1 || dados.cidade.length > 0;
        setDisabledCEP(dCep);
        if (dCep) setDados({ ...dados, cepInicial: '', cepFinal: '' });

        // eslint-disable-next-line
    }, [dados.uf, dados.cidade])

    useEffect(() => {
        //O return só executa quando o componente é desmontado
        return () => setEmpresaReadOnly(false);
    }, [setEmpresaReadOnly]);

    useEffect(() => {
        setEmpresaReadOnly(step !== 1)
    }, [step, setEmpresaReadOnly])

    const handleSetDataMunicipio = async params => {
        if (dados.uf.length !== 1) return { rows: [] }
        return await apiRFB.getMunicipios(dados.uf[0], params)
    }

    const handleChangeCep = (value, event) => {
        if (event.currentTarget.name === 'cepInicial') {
            const cep = [(value || '').replace('-', '').trim(), dados.cep[1] || '']
            setDados({ ...dados, cep: cep })
        } else {
            const cep = [dados.cep[0] || '', (value || '').replace('-', '').trim()];
            setDados({ ...dados, cep: cep })
        }
    }
    const handleChangeCapitalSocial = (value, name) => {
        if (name === 'capitalSocialInicial') {
            const capitalSocial = [value || '', dados.capitalSocial[1] || '']
            setDados({ ...dados, capitalSocial: capitalSocial })
        } else {
            const capitalSocial = [dados.capitalSocial[0] || '', value || ''];
            setDados({ ...dados, capitalSocial: capitalSocial })
        }
    }

    const handleClickClear = () => {
        setDados(defaultDados)
        if (salvaFiltroRef.current) salvaFiltroRef.current.clear()
    }
    const handleClickSendRequest = async () => {
        setWaiting(true);
        try {
            const result = await apiLeads.countLeads({
                ...dados,
                idEmpresa: empresaId
            })

            setStep(2);
            setLeadsCount(result.count);
            setNovoSaldo(saldoCredito - result.count);
        } catch (error) {
            showError(error);
        }
        setWaiting(false);
    }

    const handleClickCancel = () => {
        setStep(1);
        setWaiting(false);
        setLeadsCount(null);
        setCsvFileName(null);
    }
    const handleClickGenerateLeads = async () => {
        setWaiting(true)
        try {
            const result = await apiLeads.getLeads({
                ...dados,
                idEmpresa: empresaId,
                leadsCount: leadsCount
            })

            setStep(3)
            setCsvFileName(result.csvFileName)
            setIdLeads(result.idLeads)
        } catch (error) {
            showError(error);
        }
        setWaiting(false);
    }

    const handleClickDownload = async () => {
        setDownloading(true);
        try {
            await apiLeads.downloadLeads({
                idEmpresa: empresaId,
                fileName: csvFileName
            })
        } catch (error) {
            showError(error);
        }
        setDownloading(false);
    }

    const validateSalvaFiltro = () => {
        if ((!dados.cnae || !dados.cnae.length) &&
            (!dados.cidade || !dados.cidade.length) &&
            (!dados.razaoSocial || !dados.razaoSocial.length) &&
            (!dados.socio || !dados.socio.length)) {
            showError({ message: "Parâmetro CNAE, Cidade, Sócio ou Razão Social deve ser informado." })
            return false
        }

        return true
    }

    const toaster = useToaster();
    const showError = error => showMessage({ toaster, errorObject: error })

    const selectCnaeRef = useRef(null)
    const selectUfRef = useRef(null)
    const selectMunRef = useRef(null)
    const selectQualifRef = useRef(null)
    const salvaFiltroRef = useRef(null)

    return (
        <Stack direction='column' alignItems={null} className='consulta-leads'>
            <div className='datatable-title'>
                Geração de Leads
            </div>

            <Panel shaded bordered className='data-panel'>
                <Form fluid>
                    <FlexboxGrid className="flex-grid-form" align="top" justify="space-around">
                        <FlexboxGrid.Item as={Col} colspan={24} lg={24} >
                            <Stack justifyContent="space-between">
                                <Stack spacing={30}>
                                    <Form.Group controlId="considerarCnaeSecundaria">
                                        <Form.Control
                                            name="considerarCnaeSecundaria"
                                            id="considerarCnaeSecundaria"
                                            accepter={Checkbox}
                                            readOnly={step !== 1 || waiting}
                                            checked={dados.considerarCnaeSecundaria === 'S'}
                                            onChange={(_, checked) => {
                                                if (checked) setDados({ ...dados, considerarCnaeSecundaria: 'S' })
                                                else setDados({ ...dados, considerarCnaeSecundaria: 'N', deveConterTodosCnaes: 'N' })
                                            }}
                                        >
                                            Trazer também empresas com CNAE's secundários
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="deveConterTodosCnaes">
                                        <Form.Control
                                            name="deveConterTodosCnaes"
                                            id="deveConterTodosCnaes"
                                            accepter={Checkbox}
                                            disabled={dados.considerarCnaeSecundaria !== 'S' || dados.cnae.length < 2}
                                            readOnly={step !== 1 || waiting}
                                            checked={dados.considerarCnaeSecundaria !== 'S' ? false : dados.deveConterTodosCnaes === 'S'}
                                            onChange={(_, checked) => setDados({ ...dados, deveConterTodosCnaes: checked ? 'S' : 'N' })}
                                        >
                                            Empresas devem conter todos os CNAE's informados (principal e/ou secundário)
                                        </Form.Control>
                                    </Form.Group>
                                </Stack>

                                <SelectSalvaFiltro
                                    ref={salvaFiltroRef}
                                    idTela={'LEADS'}
                                    validateParametros={validateSalvaFiltro}
                                    getParametros={() => dados}
                                    onSelectFilter={async params => {

                                        const dataInicial = params.dataInicial ? new Date(params.dataInicial) : null
                                        const dataFinal = params.dataFinal ? new Date(params.dataFinal) : null

                                        setDados({ ...dados, ...params, dataInicial, dataFinal })

                                        //definindo os valores para os combos
                                        await selectCnaeRef.current.setValues(params.cnae)
                                        await selectUfRef.current.setValues(params.uf)
                                        await selectMunRef.current.setValues(params.cidade)
                                        await selectQualifRef.current.setValues(params.qualificacaoSocio)
                                    }}
                                />
                            </Stack>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={24} md={24}>
                            <SelectTabelasRFB
                                ref={selectCnaeRef}
                                readOnly={step !== 1 || waiting}
                                controlName={"cnae"}
                                controlLabel={"CNAE"}
                                block
                                apiSetData={params => apiRFB.getCnaes(params)}
                                value={dados.cnae}
                                onChange={value => {
                                    if (value.length > 1) setDados({ ...dados, cnae: value })
                                    else setDados({ ...dados, cnae: value, deveConterTodosCnaes: 'N' })
                                }}
                                onOpen={() => setDisableButton(true)}
                                onClose={() => setDisableButton(false)}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={8} md={24}>
                            <SelectTabelasRFB
                                ref={selectUfRef}
                                readOnly={step !== 1 || waiting}
                                controlName={"uf"}
                                controlLabel={"UF"}
                                block
                                apiSetData={params => apiRFB.getUFs(params)}
                                value={dados.uf}
                                onChange={value => setDados({ ...dados, uf: value })}
                                onOpen={() => setDisableButton(true)}
                                onClose={() => setDisableButton(false)}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={8} md={24}>
                            <SelectTabelasRFB
                                ref={selectMunRef}
                                readOnly={step !== 1 || waiting}
                                controlName={"cidade"}
                                controlLabel={"Cidade (código IBGE)"}
                                disabled={disabledMunicipio}
                                block
                                apiSetData={handleSetDataMunicipio}
                                customLabel={item => `${item.municipio} (${item.codigo_ibge})`}
                                customRender={item => item.municipio}
                                value={dados.cidade}
                                onChange={value => { setDados({ ...dados, cidade: value }) }}
                                onOpen={() => setDisableButton(true)}
                                onClose={() => setDisableButton(false)}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={8} md={24}>
                            <Form.Group controlId="cepInicial">
                                <Form.ControlLabel>CEP</Form.ControlLabel>
                                <InputGroup disabled={disabledCEP}>
                                    <InputGroup.Addon>de</InputGroup.Addon>
                                    <Form.Control
                                        readOnly={step !== 1 || waiting}
                                        name="cepInicial"
                                        id="cepInicial"
                                        accepter={MaskedInput}
                                        mask={mask.cep}
                                        placeholderChar={'\u2000'}
                                        placeholder={'00000-000'}
                                        value={dados.cep[0] || ''}
                                        onChange={handleChangeCep}
                                    />
                                    <InputGroup.Addon>até</InputGroup.Addon>
                                    <Form.Control
                                        readOnly={step !== 1 || waiting}
                                        name="cepFinal"
                                        id="cepFinal"
                                        accepter={MaskedInput}
                                        mask={mask.cep}
                                        placeholderChar={'\u2000'}
                                        placeholder={'00000-000'}
                                        value={dados.cep[1] || ''}
                                        onChange={handleChangeCep}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={12} md={24}>
                            <Form.Group controlId="dataInicial">
                                <Form.ControlLabel>Data de Abertura</Form.ControlLabel>
                                <InputGroup>
                                    <InputGroup.Addon>de</InputGroup.Addon>
                                    <Form.Control
                                        readOnly={step !== 1 || waiting}
                                        name="dataInicial"
                                        id="dataInicial"
                                        accepter={DatePicker}
                                        block
                                        oneTap
                                        format="dd/MM/yyyy"
                                        placeholder='data inicial'
                                        value={dados.dataInicial}
                                        onChange={value => setDados({ ...dados, dataInicial: value })}
                                    />
                                    <InputGroup.Addon>até</InputGroup.Addon>
                                    <Form.Control
                                        readOnly={step !== 1 || waiting}
                                        name="dataFinal"
                                        id="dataFinal"
                                        accepter={DatePicker}
                                        block
                                        oneTap
                                        format="dd/MM/yyyy"
                                        placeholder='data final'
                                        value={dados.dataFinal}
                                        onChange={value => setDados({ ...dados, dataFinal: value })}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={12} md={24}>
                            <Form.Group controlId="capitalSocialInicial">
                                <Form.ControlLabel>Capital Social</Form.ControlLabel>
                                <InputGroup>
                                    <InputGroup.Addon>de</InputGroup.Addon>
                                    <InputGroup.Addon>R$</InputGroup.Addon>
                                    <Form.Control
                                        readOnly={step !== 1 || waiting}
                                        className="currencyInput"
                                        name="capitalSocialInicial"
                                        id="capitalSocialInicial"
                                        accepter={CurrencyInput}
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        allowNegativeValue={false}
                                        placeholder={'0,00'}
                                        value={dados.capitalSocial[0] || ''}
                                        onValueChange={handleChangeCapitalSocial}
                                    />
                                    <InputGroup.Addon>até</InputGroup.Addon>
                                    <InputGroup.Addon>R$</InputGroup.Addon>
                                    <Form.Control
                                        readOnly={step !== 1 || waiting}
                                        className="currencyInput"
                                        name="capitalSocialFinal"
                                        id="capitalSocialFinal"
                                        accepter={CurrencyInput}
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        allowNegativeValue={false}
                                        placeholder={'0,00'}
                                        value={dados.capitalSocial[1] || ''}
                                        onValueChange={handleChangeCapitalSocial}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={12} md={24}>
                            <Form.Group controlId="opcaoMei">
                                <Form.ControlLabel>Opção pelo MEI</Form.ControlLabel>
                                <Form.Control
                                    className="radio-group"
                                    readOnly={step !== 1 || waiting}
                                    accepter={RadioGroup}
                                    name="opcaoMei"
                                    inline
                                    appearance="picker"
                                    value={dados.opcaoMei}
                                    onChange={value => setDados({ ...dados, opcaoMei: value })}
                                >
                                    <Radio value="T">Todos</Radio>
                                    <Radio value="S">Só optantes</Radio>
                                    <Radio value="N">Só não optantes</Radio>
                                </Form.Control>
                            </Form.Group>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={12} md={24}>
                            <Form.Group controlId="opcaoSimples">
                                <Form.ControlLabel>Opção pelo Simples</Form.ControlLabel>
                                <Form.Control
                                    className="radio-group"
                                    readOnly={step !== 1 || waiting}
                                    accepter={RadioGroup}
                                    name="opcaoSimples"
                                    inline
                                    appearance="picker"
                                    value={dados.opcaoSimples}
                                    onChange={value => setDados({ ...dados, opcaoSimples: value })}
                                >
                                    <Radio value="T">Todos</Radio>
                                    <Radio value="S">Só optantes</Radio>
                                    <Radio value="N">Só não optantes</Radio>
                                </Form.Control>
                            </Form.Group>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={24} md={24}>
                            <Form.Group controlId="socio">
                                <Form.ControlLabel>
                                    <Stack spacing={5}>
                                        <span>Sócios</span>
                                        {dados.socio.length > 0 &&
                                            <Avatar size='xs' circle style={{ background: '#1499ef' }}>
                                                <span style={{ fontSize: 14 }} >
                                                    {dados.socio.length}
                                                </span>
                                            </Avatar>
                                        }
                                        <HelpTooltip text={'Use [Enter] para confirmar a digitação e adicionar múltiplos termos para o nome'} />
                                    </Stack>
                                </Form.ControlLabel>
                                <div className="consulta-leads-busca-termo-container">
                                    <div className="consulta-leads-busca-termo">
                                        <Form.Control
                                            readOnly={step !== 1 || waiting}
                                            name="socio"
                                            id="socio"
                                            accepter={TagInput}
                                            trigger={['Enter']}
                                            block
                                            placeholder={'Use [Enter] para confirmar o texto digitado'}
                                            value={dados.socio}
                                            onChange={value => setDados({ ...dados, socio: !value ? [] : value.map(v => v.toUpperCase()) })}
                                        />
                                    </div>
                                    <div className="consulta-leads-busca-termo-tipo">
                                        <Form.Control
                                            readOnly={step !== 1 || waiting}
                                            name="socioTipoBusca"
                                            id="socioTipoBusca"
                                            accepter={SelectPicker}
                                            searchable={false}
                                            block
                                            data={tipoBusca('Nome')}
                                            value={dados.socioTipoBusca}
                                            onChange={value => setDados({ ...dados, socioTipoBusca: value || 0 })}
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24}>
                            <SelectTabelasRFB
                                ref={selectQualifRef}
                                readOnly={step !== 1 || waiting}
                                controlName={"qualificacaoSocio"}
                                controlLabel={"Qualificação do Sócio"}
                                block
                                apiSetData={params => apiRFB.getQualiicacao(params)}
                                value={dados.qualificacaoSocio}
                                onChange={value => { setDados({ ...dados, qualificacaoSocio: value }) }}
                                onOpen={() => setDisableButton(true)}
                                onClose={() => setDisableButton(false)}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={24} md={24}>
                            <Form.Group controlId="razaoSocial">
                                <Form.ControlLabel>
                                    <Stack spacing={5}>
                                        <span>Razão Social</span>
                                        {dados.razaoSocial.length > 0 &&
                                            <Avatar size='xs' circle style={{ background: '#1499ef' }}>
                                                <span style={{ fontSize: 14 }} >
                                                    {dados.razaoSocial.length}
                                                </span>
                                            </Avatar>
                                        }
                                        <HelpTooltip text={'Use [Enter] para confirmar a digitação e adicionar múltiplos termos para a razão social'} />
                                    </Stack>
                                </Form.ControlLabel>
                                <div className="consulta-leads-busca-termo-container">
                                    <div className="consulta-leads-busca-termo">
                                        <Form.Control
                                            readOnly={step !== 1 || waiting}
                                            name="razaoSocial"
                                            id="razaoSocial"
                                            accepter={TagInput}
                                            trigger={['Enter']}
                                            block
                                            placeholder={'Use [Enter] para confirmar o texto digitado'}
                                            value={dados.razaoSocial}
                                            onChange={value => setDados({ ...dados, razaoSocial: !value ? [] : value.map(v => v.toUpperCase()) })}
                                        />
                                    </div>
                                    <div className="consulta-leads-busca-termo-tipo">
                                        <Form.Control
                                            readOnly={step !== 1 || waiting}
                                            name="razaoSocialTipoBusca"
                                            id="razaoSocialTipoBusca"
                                            accepter={SelectPicker}
                                            searchable={false}
                                            block
                                            data={tipoBusca('Razão')}
                                            value={dados.razaoSocialTipoBusca}
                                            onChange={value => setDados({ ...dados, razaoSocialTipoBusca: value || 0 })}
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={24}>
                            <Form.Group controlId="considerarRecuperacaoJudicial">
                                <Form.Control
                                    name="considerarRecuperacaoJudicial"
                                    accepter={Checkbox}
                                    readOnly={step !== 1 || waiting}
                                    checked={dados.considerarRecuperacaoJudicial === 'S'}
                                    onChange={(value, checked) => setDados({ ...dados, considerarRecuperacaoJudicial: checked ? 'S' : 'N' })}
                                >
                                    Considerar também empresas em Recuperação Judicial
                                </Form.Control>
                            </Form.Group>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={24}>
                            <Form.Group controlId="desconsiderarCliente">
                                <Form.ControlLabel>
                                    Desconsiderar meus clientes cadastrados
                                </Form.ControlLabel>
                                <Form.Control
                                    className="radio-group"
                                    readOnly={step !== 1 || waiting}
                                    accepter={RadioGroup}
                                    name="desconsiderarCliente"
                                    appearance="picker"
                                    value={dados.desconsiderarCliente}
                                    onChange={value => setDados({ ...dados, desconsiderarCliente: value })}
                                >
                                    <Radio value="B" className="consulta-leads-radio">Pelo CNPJ base. Não trazer leads de clientes já cadastrados nem de suas filiais que eu não possuo. <span className="bold red">({totalClientesPorCnpjBasico})</span></Radio>
                                    <Radio value="C" className="consulta-leads-radio">Pelo CNPJ completo. Não trazer leads de clientes já cadastrados mas trazer suas filiais que eu não possuo. <span className="bold red">({totalClientes})</span></Radio>
                                    <Radio value="N" className="consulta-leads-radio">Não desconsiderar. Trazer leads mesmo que já seja meu cliente</Radio>
                                </Form.Control>
                            </Form.Group>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Form>

                <div className="footer-data-panel">
                    {step === 1 &&
                        <>
                            {waiting
                                ? <Loader content='Aguarde, calculando a quantidade de leads.' />
                                : <Stack spacing={10} justifyContent='flex-end'>
                                    <Button appearance="ghost" color="green" onClick={handleClickSendRequest} disabled={disableButton}>Enviar solicitação</Button>
                                    <Button appearance="ghost" color="orange" onClick={handleClickClear} disabled={disableButton}>Limpar todos os parâmetros</Button>
                                </Stack>
                            }
                        </>
                    }
                    {step === 2 &&
                        <>
                            {waiting
                                ? <Loader content="Aguarde, gerando leads. Essa operação pode demorar alguns minutos." />
                                : <Stack alignItems="center" justifyContent="space-between">
                                    <Stack spacing={20}>
                                        <Tag color={leadsCount === 0 ? 'red' : 'blue'} className="tag-count">
                                            Leads encontrados: <span className="emphasis">{leadsCount.toLocaleString('pt-BR')}</span>
                                        </Tag>
                                        {leadsCount > 0 &&
                                            <>
                                                <Tag color="green" className="tag-count">
                                                    Saldo atual de créditos: <span className="emphasis">{saldoCredito.toLocaleString('pt-BR')}</span>
                                                </Tag>
                                                <Tag color={novoSaldo < 0 ? "red" : "green"} className="tag-count">
                                                    {novoSaldo > 0
                                                        ? <span>Saldo após a geração dos leads: <span className="emphasis">{novoSaldo.toLocaleString('pt-BR')}</span></span>
                                                        : <span>Saldo insuficiente</span>
                                                    }
                                                </Tag>
                                            </>
                                        }
                                    </Stack>
                                    <Stack spacing={10}>
                                        {novoSaldo >= 0 && leadsCount !== 0 &&
                                            <Button appearance="ghost" color="green" onClick={handleClickGenerateLeads}>Gerar leads</Button>
                                        }
                                        <Button appearance="ghost" color="orange" onClick={handleClickCancel} >Cancelar</Button>
                                    </Stack>
                                </Stack>
                            }
                        </>
                    }
                    {step === 3 && csvFileName &&
                        <Stack alignItems="center" justifyContent="space-between">
                            <Stack alignItems="flex-start" spacing={10}>
                                <Button
                                    className="download-button"
                                    appearance={downloading ? "primary" : "ghost"}
                                    color={downloading ? "yellow" : "blue"}
                                    onClick={handleClickDownload}
                                >
                                    {downloading
                                        ? <Stack spacing={10} alignItems="center" justifyContent="center">
                                            <Loader content="Aguarde ..." />
                                        </Stack>
                                        : 'Baixar o arquivo'}
                                </Button>
                                {user.nivel >= 3 && permiteTelaSelecaoLeads
                                    ? <Link to="/envialeads" state={{ idLeads: idLeads }}>
                                        <Button appearance="ghost" color="green">
                                            Selecionar Leads para enviar ou buscar por API
                                        </Button>
                                    </Link>
                                    : <></>
                                }
                            </Stack>
                            <Button appearance="ghost" color="orange" onClick={handleClickCancel} >Cancelar</Button>
                        </Stack>
                    }
                </div>
                {(step !== 1 || waiting) &&
                    <>
                        <Divider />
                        <Stack justifyContent="flex-end">
                            <Tag color="yellow" style={{ color: '#333' }}>
                                Os parâmetros não podem ser alterados nesse momento
                                {waiting ? '.' : ', Clique em cancelar para alterá-los.'}
                            </Tag>
                        </Stack>
                    </>
                }
                {step === 2 && waiting &&
                    <>
                        <Divider />
                        <Tag className="message-download">
                            <p>
                                Você pode fechar esta tela. Após a conclusão, um arquivo CSV será enviado para o seu e-mail e também ficará disponível para download durante 7 dias.
                            </p>
                            <p>
                                Se preferir aguardar, no final da operação será habilitado um botão para fazer o download desse arquivo. Ainda assim ele será enviado por e-mail.
                            </p>
                        </Tag>
                    </>
                }
            </Panel>
        </Stack >
    )
}

export default LeadsConsultar
