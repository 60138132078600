import { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Col, FlexboxGrid, Form, Panel, Radio, RadioGroup, Stack } from "rsuite";

import ComboSemPaginacao from "../../components/ComboSemPaginacao";
import SelectSalvaFiltro from "../../components/SelectSalvaFiltro";
import SelectTabelasRFB from "../../components/SelectTabelasRFB";
import VerticalTitle from "../../components/VerticalTitle";

import { useApiEstatisticas } from "../../hooks/useApiEstatisticas";
import { useApiRFBTabelas } from '../../hooks/useApiRFBTabelas';

import './style.css';

const CustomHeader = (
    {
        loading,
        filterFields,
        setFilterFields,
        formatoDemonstracao,
        setFormatoDemonstracao,
        onClickApplyFilter,
        onClickClearFilter,
    }
) => {

    const apiRFB = useApiRFBTabelas()
    const apiEstatisticas = useApiEstatisticas()

    const [disabledMunicipio, setDisabledMunicipio] = useState(true);

    useEffect(() => {
        const dMun = filterFields.uf.length !== 1;
        setDisabledMunicipio(dMun);
        if (dMun) setFilterFields({ ...filterFields, cidade: [] });

        // eslint-disable-next-line
    }, [filterFields.uf])

    const handleSetDataMunicipio = async params => {
        if (filterFields.uf.length !== 1) return { rows: [] }
        return await apiRFB.getMunicipios(filterFields.uf[0], params)
    }

    const handleClickFormatoDemonstracao = () => {
        onClickApplyFilter()

        if (formatoDemonstracao === 1) setFormatoDemonstracao(2)
        else setFormatoDemonstracao(1)
    }

    const handleClickClear = () => {
        if (salvaFiltroRef.current) salvaFiltroRef.current.clear()
        if (onClickClearFilter) onClickClearFilter()
    }

    const selectCnaeRef = useRef(null)
    const selectUfRef = useRef(null)
    const selectMunRef = useRef(null)
    const selectFaixaCapitalRef = useRef(null)
    const selectFaixaIdadeRef = useRef(null)
    const salvaFiltroRef = useRef(null)

    return (
        <Panel bordered className="estatistica-cnae-custom-header">
            <VerticalTitle verticalTitle={"Filtros"}>
                <Form fluid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item as={Col} colspan={24} lg={7} md={12}>
                            <SelectTabelasRFB
                                ref={selectUfRef}
                                controlName={"uf"}
                                controlLabel={"UF"}
                                block
                                apiSetData={params => apiRFB.getUFs(params)}
                                value={filterFields.uf}
                                onChange={value => setFilterFields({ ...filterFields, uf: value })}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={7} md={12}>
                            <SelectTabelasRFB
                                ref={selectMunRef}
                                controlName={"cidade"}
                                controlLabel={"Cidade (código IBGE)"}
                                disabled={disabledMunicipio}
                                block
                                apiSetData={handleSetDataMunicipio}
                                customLabel={item => `${item.municipio} (${item.codigo_ibge})`}
                                customRender={item => item.municipio}
                                value={filterFields.municipio}
                                onChange={value => { setFilterFields({ ...filterFields, municipio: value }) }}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={6} md={12}>
                            <ComboSemPaginacao
                                ref={selectFaixaCapitalRef}
                                controlName={"faixaCapitalSocial"}
                                controlLabel={"Capital Social"}
                                block
                                apiSetData={apiEstatisticas.getFaixaCapitalSocial}
                                customLabel={item => item.descricao}
                                customRender={item => item.descricao}
                                value={filterFields.faixaCapitalSocial}
                                onChange={value => setFilterFields({ ...filterFields, faixaCapitalSocial: value })}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={4} md={12}>
                            <ComboSemPaginacao
                                ref={selectFaixaIdadeRef}
                                controlName={"faixaIdadeEmpresa"}
                                controlLabel={"Idade da Empresa"}
                                block
                                apiSetData={apiEstatisticas.getFaixaIdadeEmpresa}
                                customLabel={item => item.descricao}
                                customRender={item => item.descricao}
                                value={filterFields.faixaIdadeEmpresa}
                                onChange={value => setFilterFields({ ...filterFields, faixaIdadeEmpresa: value })}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={24}>
                            <SelectTabelasRFB
                                ref={selectCnaeRef}
                                controlName={"cnae"}
                                controlLabel={"CNAE"}
                                block
                                apiSetData={params => apiRFB.getCnaes(params)}
                                value={filterFields.cnae}
                                onChange={value => {
                                    setFilterFields({ ...filterFields, cnae: value })
                                }}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={6} md={12}>
                            <Form.Group controlId="opcaoMei">
                                <Form.ControlLabel>Opção pelo MEI</Form.ControlLabel>
                                <Form.Control
                                    className="radio-group"
                                    accepter={RadioGroup}
                                    name="opcaoMei"
                                    inline
                                    appearance="picker"
                                    value={filterFields.opcaoMei}
                                    onChange={value => setFilterFields({ ...filterFields, opcaoMei: value })}
                                >
                                    <Radio value="T">Todos</Radio>
                                    <Radio value="S">Só optantes</Radio>
                                    <Radio value="N">Só não optantes</Radio>
                                </Form.Control>
                            </Form.Group>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={6} md={12}>
                            <Form.Group controlId="opcaoSimples">
                                <Form.ControlLabel>Opção pelo Simples</Form.ControlLabel>
                                <Form.Control
                                    className="radio-group"
                                    accepter={RadioGroup}
                                    name="opcaoSimples"
                                    inline
                                    appearance="picker"
                                    value={filterFields.opcaoSimples}
                                    onChange={value => setFilterFields({ ...filterFields, opcaoSimples: value })}
                                >
                                    <Radio value="T">Todos</Radio>
                                    <Radio value="S">Só optantes</Radio>
                                    <Radio value="N">Só não optantes</Radio>
                                </Form.Control>
                            </Form.Group>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={5} className="cnae-sem-cliente">
                            <Stack spacing={30}>
                                <Form.Group controlId="exibirCnaeSemCliente">
                                    <Form.Control
                                        name="exibirCnaeSemCliente"
                                        id="exibirCnaeSemCliente"
                                        accepter={Checkbox}
                                        checked={filterFields.exibirCnaeSemCliente}
                                        onChange={(_, checked) => setFilterFields({ ...filterFields, exibirCnaeSemCliente: checked })}
                                    >
                                        Exibir CNAE's sem clientes
                                    </Form.Control>
                                </Form.Group>
                            </Stack>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={24}>
                            <Stack className="stack-footer" justifyContent="space-between">
                                <Stack spacing={15}>
                                    <Button color="cyan" appearance="ghost" block onClick={onClickApplyFilter} disabled={loading}>Aplicar filtros</Button>
                                    <Button color="yellow" appearance="ghost" block onClick={handleClickClear} disabled={loading}>Limpar filtros</Button>
                                    <SelectSalvaFiltro
                                        //  ref={salvaFiltroRef}
                                        idTela={'ESTATISTICA'}
                                        getParametros={() => filterFields}
                                        onSelectFilter={async params => {
                                            setFilterFields({ ...filterFields, ...params })

                                            //definindo os valores para os combos
                                            await selectCnaeRef.current.setValues(params.cnae)
                                            await selectUfRef.current.setValues(params.uf)
                                            await selectMunRef.current.setValues(params.municipio)
                                            await selectFaixaCapitalRef.current.setValues(params.faixaCapitalSocial)
                                            await selectFaixaIdadeRef.current.setValues(params.faixaIdadeEmpresa)
                                        }}
                                    />
                                </Stack>
                                <Button color="blue" appearance="ghost" block onClick={handleClickFormatoDemonstracao} disabled={loading}>
                                    {formatoDemonstracao === 1
                                        ? "Exibir dados no formato UF / Município / CNAE"
                                        : "Exibir dados no formato CNAE / UF / Município"
                                    }
                                </Button>
                            </Stack>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            </VerticalTitle>
        </Panel>
    )
}

export default CustomHeader