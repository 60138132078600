import { useContext, useEffect, useState } from 'react';

import { AuthContext } from './authContext';
import { EmpresaContext } from './empresaContext';

import { useApiAuth } from '../hooks/useApiAuth';
import { useApiPerfil } from '../hooks/useApiPerfil';

import { setToken } from '../services/token';

export const AuthProvider = ({ children }) => {

    const apiAuth = useApiAuth();
    const apiPerfil = useApiPerfil();

    const { setEmpresaId, setPermiteTelaSelecaoLeads, setPermiteApiPropriaLeads } = useContext(EmpresaContext);

    const [user, setUser] = useState(null);
    const [darkMode, setDarkMode] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const token = sessionStorage.getItem('token');
            if (token) {
                try {
                    const data = await apiAuth.validate();
                    if (data.user) {
                        setUser(data.user)
                        setEmpresaId(data.user.empresaId)
                        setPermiteTelaSelecaoLeads(data.user.empresaPermiteTelaSelecaoLeads)
                        setPermiteApiPropriaLeads(data.user.empresaPermiteApiPropriaLeads)
                        setDarkMode(data.darkMode)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            setLoading(false)
        })();
        // eslint-disable-next-line
    }, []);

    const login = async (email, password) => {
        try {
            const data = await apiAuth.login(email, password)

            if (data.user && data.token) {
                setUser(data.user)
                setEmpresaId(data.user.empresaId)
                setPermiteTelaSelecaoLeads(data.user.empresaPermiteTelaSelecaoLeads)
                setPermiteApiPropriaLeads(data.user.empresaPermiteApiPropriaLeads)
                setDarkMode(data.darkMode)
                setToken(data.token)
                return true
            }
        } catch (error) {
            console.log(error)
        }
    }

    const logout = () => {
        setUser(null);
        setToken('');
    }

    const toggleDarkMode = async () => {
        const newDarkMode = !darkMode;
        await apiPerfil.saveDarkMode(newDarkMode);
        setDarkMode(newDarkMode)
    }

    const savePerfil = async perfil => {
        const response = await apiPerfil.savePerfil(perfil);
        if (response.status === 200) setUser(perfil);
        return response.response || response;
    }

    return (
        <AuthContext.Provider value={{ signed: Boolean(user), user, loading, darkMode, login, logout, toggleDarkMode, savePerfil }}>
            {children}
        </AuthContext.Provider>
    );
};