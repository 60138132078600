import './style.css'

const Title = ({ text }) => {
    return (
        <div className='title-text'>
            {text}
        </div>
    )
}

export default Title
