import { Route } from 'react-router-dom';

import Login from '../pages/Login';
import RecuperaSenha from '../pages/RecuperaSenha';

const createOpenRoutes = () => (
    <>
        <Route path='/login' element={<Login />} />
        <Route path='/recuperasenha' element={<RecuperaSenha />} />
    </>
)

export default createOpenRoutes;