import { useEffect, useMemo, useState } from "react";
import { Button, Col, FlexboxGrid } from "rsuite";

import SearchIcon from '@rsuite/icons/Search';

import DataTable from "../../components/DataTable";

import { dateFromString, formataCNPJ } from "../../services/utils";

import './style.css';

const DadosEmpresaMesmoSocio = ({ dados, handleViewEmpresa }) => {
    const [dataTableParams, setDataTableParams] = useState()
    const [empresas, setEmpresas] = useState([])

    const dataTableColumns = {
        columns: [
            {
                dataKey: 'cnpj',
                sortable: false,
                width: 36,
                style: { padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' },
                customCellContent: (rowData, col) => (
                    <Button appearance="ghost" color="cyan" size="sm" style={{ padding: "4px 6px" }} onClick={() => handleViewEmpresa(rowData[col.dataKey])}>
                        <SearchIcon />
                    </Button>
                )
            }, {
                dataKey: 'cnpj',
                headerCell: 'CNPJ',
                width: 160,
                sortable: true,
                customCellContent: (rowData, col) => formataCNPJ(rowData[col.dataKey])
            }, {
                dataKey: 'razao_social',
                headerCell: 'Razão Social',
                flexGrow: 2,
                sortable: true,
                fullText: true
            }, {
                dataKey: 'capital_social',
                headerCell: 'Capital Social',
                width: 180,
                sortable: true,
                align: 'right',
                customCellContent: (rowData, col) => {
                    const valor = rowData[col.dataKey]
                    if (!valor) return ""
                    return valor.toLocaleString('pt-br', { style: "currency", currency: 'BRL' })
                }
            }, {
                dataKey: 'qualificacao_socio',
                headerCell: 'Qualificação',
                minWidth: 200,
                flexGrow: 1,
                sortable: true,
                fullText: true
            }, {
                dataKey: 'data_entrada_sociedade',
                headerCell: 'Data Entrada Sociedade',
                width: 180,
                sortable: true,
                customCellContent: (rowData, col) => {
                    const date = dateFromString(rowData[col.dataKey])
                    return date ? date.toLocaleDateString('pt-br') : ''
                }
            }
        ]
    }

    const dadosEmpresas = useMemo(() => {
        return !dados
            ? []
            : dados.map((c, index) => ({
                cnpj: c.cnpj,
                razao_social: c.razao_social,
                qualificacao_socio: c.qualificacao_socio,
                data_entrada_sociedade: c.data_entrada_sociedade,
                capital_social: c.capital_social
            }))
    }, [dados])

    const ordenador = (a, b) => {
        if (!dataTableParams || !a || !b) return 0

        const sortColumn = dataTableParams.sortColumn || 'data_entrada_sociedade'
        const sortType = dataTableParams.sortType || 'asc'

        if (sortType === 'asc') {
            if (a[sortColumn] > b[sortColumn]) return 1
            if (a[sortColumn] < b[sortColumn]) return -1
        } else {
            if (a[sortColumn] < b[sortColumn]) return 1
            if (a[sortColumn] > b[sortColumn]) return -1
        }

        return 0;
    }

    useEffect(() => {
        if (!dadosEmpresas.length || !dataTableParams) {
            setEmpresas({ data: [] })
        } else {
            const mapEmpresas = dadosEmpresas
                .map(c => c)
                .filter(c => (
                    c.razao_social.toLowerCase().includes(dataTableParams.searchText.toLowerCase()) ||
                    c.qualificacao_socio.toLowerCase().includes(dataTableParams.searchText.toLowerCase()) ||
                    c.data_entrada_sociedade.includes(dataTableParams.searchText)
                ))
                .sort(ordenador)

            setEmpresas({
                data: mapEmpresas,
                total: dadosEmpresas.length,
                filtered: mapEmpresas.length
            })
        }
        // eslint-disable-next-line
    }, [dataTableParams, dadosEmpresas])

    const subTitle = `+${empresas.total} Empresa${empresas.total > 1 ? `s` : ''} que tem sociedade ${empresas.filtered !== empresas.total ? `(exibindo ${empresas.filtered})` : ''}`

    return (
        <FlexboxGrid justify="start" align="bottom">
            <FlexboxGrid.Item as={Col} colspan={24}>
                <DataTable
                    subtitle={subTitle}
                    hidePagination={true}
                    data={empresas}
                    dataTableColumns={dataTableColumns}
                    hiddeNewButton={true}
                    autoHeight={false}
                    height={350}
                    onChangeParams={setDataTableParams}
                    rowClassName={'socios-table-row'}
                />
            </FlexboxGrid.Item>
        </FlexboxGrid>
    )
}

export default DadosEmpresaMesmoSocio