import { useContext, useEffect, useState } from "react";
import { Popover, Stack, Table, Whisper, useToaster } from "rsuite";

import SearchIcon from '@rsuite/icons/Search';

import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/ShowMessage";

import { EmpresaContext } from "../../contexts/empresaContext";
import { useApiExtrato } from "../../hooks/useApiExtrato";
import { dateFromString } from "../../services/utils";

const { Cell } = Table;

const CellHistorico = ({ rowData, ...props }) => {
    const speaker = (
        <Popover title="Parâmetros">
            <div>
                <pre>
                    {JSON.stringify(JSON.parse(rowData.parametros), null, 2)}
                </pre>
            </div>
        </Popover>
    );

    return (
        <Cell {...props}>
            <div style={!rowData['concluido'] ? { color: '#3498ff' } : {}}>
                <Stack spacing={15}>
                    {rowData['historico']}
                    {rowData['parametros']
                        && <Whisper placement="right" speaker={speaker}>
                            <SearchIcon />
                        </Whisper>
                    }
                </Stack>
            </div>
        </Cell>
    );
};

const Extrato = () => {
    const { empresaId } = useContext(EmpresaContext)
    const apiExtrato = useApiExtrato()

    const [extrato, setExtrato] = useState({});

    const [dataTableParams, setDataTableParams] = useState()
    const [loading, setLoading] = useState(true);

    const getReportCreditos = async () => {
        setLoading(true)
        if (!empresaId || !dataTableParams) {
            setExtrato({});
        } else {
            try {
                const data = await apiExtrato.getExtrato({ ...dataTableParams, idEmpresa: empresaId })
                setExtrato({
                    data: data.rows,
                    total: data.total,
                    filtered: data.filtered
                });
            } catch (error) {
                setExtrato({});
                showError(error)
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        (async () => await getReportCreditos())()
        // eslint-disable-next-line
    }, [empresaId, dataTableParams])

    const toaster = useToaster();
    const showError = error => showMessage({ toaster, errorObject: error })

    const dataTableColumns = {
        columns: [
            {
                dataKey: 'data',
                headerCell: 'Data',
                minWidth: 100,
                customCellContent: (rowData, col) =>
                    <div style={!rowData['concluido'] ? { color: '#3498ff' } : {}}>
                        {dateFromString(rowData[col.dataKey]).toLocaleDateString('pt-BR')}
                    </div>
            }, {
                dataKey: 'historico',
                headerCell: 'Histórico',
                flexGrow: 1,
                fullText: true,
                customCell: CellHistorico
            }, {
                dataKey: 'qtde',
                headerCell: 'Crédito',
                align: 'right',
                width: 100,
                customCellContent: (rowData, col) =>
                    <div style={!rowData['concluido'] ? { color: '#3498ff' } : {}}>
                        {rowData['tipoMov'] === 'E' && parseInt(rowData[col.dataKey]).toLocaleString('pt-BR')}
                    </div>
            }, {
                dataKey: 'qtde',
                headerCell: 'Débito',
                align: 'right',
                width: 100,
                customCellContent: (rowData, col) =>
                    <div style={!rowData['concluido'] ? { color: '#3498ff' } : {}}>
                        {rowData['tipoMov'] === 'S' && parseInt(rowData[col.dataKey]).toLocaleString('pt-BR')}
                    </div>
            }, {
                dataKey: 'saldo',
                headerCell: 'Saldo',
                align: 'right',
                width: 100,
                customCellContent: (rowData, col) => parseInt(rowData[col.dataKey]).toLocaleString('pt-BR')
            }
        ]
    }

    return (
        <Stack direction="column" alignItems={null}>
            <div className='datatable-title'>Extrato de Créditos</div>

            <DataTable
                data={extrato}
                dataTableColumns={dataTableColumns}
                placeholderSearch='data, quantidade, historico'
                hiddeNewButton
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
            />
        </Stack >
    )
}

export default Extrato