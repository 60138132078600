import api from '../services/api.js';

const resource = '/cnpjconsulta'

const getData = async cnpj => {
    const response = await api.get(`${resource}/${cnpj}`)
    return response.data;
}

const getSameGroup = async cnpj => {
    const response = await api.get(`${resource}/samegroup/${cnpj}`)
    return response.data;
}

const getByPartner = async ({ nomeSocio, cnpjCpfSocio, cnpjDesconsiderar }) => {
    const response = await api.get(`${resource}/partner`, {
        params: {
            nomeSocio,
            cnpjCpfSocio,
            cnpjDesconsiderar
        }
    })
    return response.data;
}

export const useApiCnpjConsulta = () => ({
    getData, getSameGroup, getByPartner
})