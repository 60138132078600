import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Avatar, CheckPicker, Form, Loader, Stack, Tag, useToaster } from 'rsuite';
import { showMessage } from '../ShowMessage';

import './style.css';

/*
  Usar para carregar lista com quantidade pequena de dados, que não precise fazer paginação e buscar várias vezes no banco de dados
*/

const ComboSemPaginacao = forwardRef(({
  controlName,
  controlLabel,
  apiSetData,
  onChange,
  onOpen,
  onClose,
  customLabel,
  customRender,
  value,
  disabled,
  readOnly,
  ...rest
}, ref) => {
  const toaster = useToaster()
  const formGroupRef = useRef()

  const [data, setData] = useState(null);
  const [valueRender, setValueRender] = useState([]);

  const [newWidth, setNewWidth] = useState()

  useEffect(() => {
    // Função para obter a largura do Form.Group
    const getNewWidth = () => {
      if (formGroupRef.current) {
        const formGroupWidth = formGroupRef.current.getBoundingClientRect().width - 1;
        const adjustedWidth = Math.ceil(formGroupWidth) - 1;
        setNewWidth(adjustedWidth)
      }
    }

    getNewWidth()

    // Adicionar ouvinte de evento de redimensionamento ao montar o componente
    window.addEventListener('resize', getNewWidth);

    // Remover o ouvinte de evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', getNewWidth);
    };

  }, [formGroupRef])

  const getData = async () => {
    try {
      if (apiSetData) {
        const result = await apiSetData();
        const rows = result.rows.map(c => ({
          label: customLabel ? customLabel(c) : `${c.codigo} - ${c.descricao}`,
          value: c.codigo,
          object: c
        }));
        setData(rows)
      }
    } catch (error) {
      setData(null);
      showMessage({ toaster, errorObject: error })
    }
  }

  const handleCloseTag = (event, codigo) => {
    event.stopPropagation();
    if (readOnly) return false;
    if (onChange) {
      setValueRender(valueRender.filter(f => f.codigo !== codigo))
      onChange(value.filter(c => c !== codigo))
    }
  }

  const renderValue = () => (
    <div className='containerPickerValue'>
      {valueRender.map((item, index) => (
        <Tag key={index} closable onClose={event => handleCloseTag(event, item.codigo)}>
          {customRender ? customRender(item) : `${item.codigo} - ${item.descricao}`}
        </Tag>
      ))}
    </div>
  )

  const renderMenu = menu => {
    return (
      <>
        {!data
          ? <Stack className="combo-load-more" spacing={10} justifyContent='center'>
            <Loader />
            <span>Buscando...</span>
          </Stack>
          : menu}
      </>
    );
  };

  const handleSelect = (values, item) => {
    const newValueRender = valueRender.filter(f => values.includes(f.codigo))

    if (values.includes(item.value) && !valueRender.find(f => f.codigo === item.value)) newValueRender.push(item.object)
    setValueRender(newValueRender)

    if (onChange) onChange(values)
  }

  const handleClean = () => onChange && onChange([]);
  const handleOpen = async () => {
    if (onOpen) onOpen();
    await getData();
  }
  const handleClose = () => {
    if (onClose) onClose();
    setData(null);
  }

  useEffect(() => {
    if (disabled) handleClean()
    // eslint-disable-next-line
  }, [disabled])

  const setValues = async values => {
    if (values.length) {
      setValueRender([])
      const data = await apiSetData({ arrayCodigos: values })
      setValueRender(data)
    }
  }

  useImperativeHandle(ref, () => ({
    setValues
  }));

  return (
    <Form.Group controlId={controlName} ref={formGroupRef}>
      <Form.ControlLabel>
        <Stack spacing={5}>
          {controlLabel || controlName}
          {
            value.length > 0 &&
            <Avatar size='xs' circle style={{ background: '#1499ef' }}>
              <span style={{ fontSize: 14 }} >
                {value.length}
              </span>
            </Avatar>
          }
        </Stack>
      </Form.ControlLabel>
      <Form.Control
        {...rest}
        style={{ width: newWidth }}
        accepter={CheckPicker}
        name={controlName}
        data={data || []}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        renderValue={renderValue}
        renderMenu={renderMenu}
        onOpen={handleOpen}
        onClean={handleClean}
        onSelect={handleSelect}
        onClose={handleClose}
      />
    </Form.Group>
  )
})

ComboSemPaginacao.propTypes = {
  controlName: PropTypes.string,
  controlLabel: PropTypes.string,
  apiSetData: PropTypes.func,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  customLabel: PropTypes.func,
  customRender: PropTypes.func,
  disabled: PropTypes.bool,
}

export default ComboSemPaginacao