import api from '../services/api.js';

const resource = '/extratos'

const getExtrato = async ({ idEmpresa, pageNumber, itemsPerPage, searchText }) => {
    const response = await api.get(`${resource}`, {
        params: {
            idEmpresa,
            pageNumber,
            itemsPerPage,
            searchText
        }
    })
    return response.data;
}

const getSaldo = async idEmpresa => {
    const response = await api.get(`${resource}/balance`, {
        params: {
            idEmpresa
        }
    })
    return response.data;
}

export const useApiExtrato = () => ({
    getExtrato,
    getSaldo
})