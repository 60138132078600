import './style.css'

const VerticalTitle = ({ children, verticalTitle }) => {
    return (
        <div className='vertical-title-container'>
            <div className='vertical-title-text-container'>
                <div className='vertical-title-text'>
                    {verticalTitle}
                </div>
            </div>
            <div className='vertical-title-content'>
                {children}
            </div>
        </div>
    )
}

export default VerticalTitle
