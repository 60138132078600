import api from '../services/api.js';

const resource = '/download'

const getDownloads = async () => {
    const response = await api.get(`${resource}`);
    return response.data;
}

export const useApiDownload = () => ({
    getDownloads
})