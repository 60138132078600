import api from '../services/api.js';

const resource = '/filtros'

const getFiltros = async idTela => {
    const response = await api.get(resource, {
        params: { idTela }
    });
    return response.data;
}

const getFiltro = async id => {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
}

const saveFiltro = filtro => {
    if (!filtro.idFiltro) {
        return api.post(resource, filtro)
    } else {
        return api.patch(`${resource}/${filtro.idFiltro}`, filtro)
    }
}

const deleteFiltro = id => api.delete(`${resource}/${id}`)

export const useApiFiltro = () => ({
    getFiltros,
    getFiltro,
    saveFiltro,
    deleteFiltro
})