import axios from 'axios';

import { setToken } from './token';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json"
    }
})

api.interceptors.request.use(request => {
    const token = sessionStorage.getItem('token');

    if (token) request.headers.authorization = `Bearer ${token}`;

    return request;
});

//saveAs(response.data, 'teste.csv');

api.interceptors.response.use(response => response, error => {
    if (error && error.response && error.response.status) {
        if (error.response.status === 401) {
            setToken('');
            window.location.replace('/login');
            return Promise.reject();
        } else if (error.response.status === 403) {
            window.location.replace('/');
            return Promise.reject();
        }
    }

    return Promise.reject(error);
});

export default api;