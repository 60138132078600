import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from "react";
import { Loader, Panel, SelectPicker, Stack } from "rsuite";
import { useApiEmpresa } from '../../hooks/useApiEmpresa';

import './style.css';

const CustomPanel = ({ hiddePanel, children }) => {
    if (hiddePanel) return children

    return (
        <Panel bordered shaded className="data-panel" style={{ marginBottom: 10 }}>
            {children}
        </Panel>
    )
}

const SelectEmpresa = ({
    showError,
    onSelectEmpresa,
    placeHolder,
    hiddePanel,
    idEmpresaDefault,
    captionValue,
    ...rest
}) => {

    const apiEmpresa = useApiEmpresa();

    const [empresas, setEmpresas] = useState([]);
    const [empresaLocal, setEmpresaLocal] = useState();

    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState('')

    const getEmpresas = async fromStart => {
        setLoading(true);
        try {
            const data = await apiEmpresa.getEmpresas({
                start: fromStart ? 0 : empresas.length,
                itemsPerPage: 20,
                searchText: searchText,
                sortColumn: 'nomeEmpresa',
                sortType: 'ASC'
            });
            const rows = data.rows.map(c => ({
                label: c.nomeEmpresa,
                value: c.idEmpresa,
                empresa: c
            }));
            if (fromStart) {
                setEmpresas(rows)
            } else {
                setEmpresas([...empresas, ...rows])
            }
        } catch (error) {
            setEmpresas([]);
            if (showError) showError(error);
        }
        setLoading(false);
    }

    const handleOpen = async () => await getEmpresas(true);

    const renderValue = () => {
        return (
            empresaLocal &&
            <div>
                <span style={{ color: '#575757', marginRight: 10 }}>{captionValue || 'Empresa :'}</span>
                {empresaLocal.label}
            </div>
        );
    }

    const loadMore = useCallback(() => (
        <Stack className="select-empresa-load-more" spacing={10} justifyContent='center'>
            <Loader />
            <span>Buscando...</span>
        </Stack>
    ), []);

    const onItemsEmpresaRendered = async props => {
        if (props.visibleStopIndex >= empresas.length - 1) await getEmpresas();
    };

    const renderMenu = menu => {
        return (
            <>
                {menu}
                {loading && loadMore()}
            </>
        );
    };

    const handleSelect = (_, item) => {
        if (onSelectEmpresa) onSelectEmpresa(item.empresa);
        setEmpresaLocal(item);
    }

    const handleClean = () => {
        if (onSelectEmpresa) onSelectEmpresa(null);
        setEmpresaLocal(null);
    }

    useEffect(() => {
        (async () => {
            if (idEmpresaDefault) {
                try {
                    const empresa = await apiEmpresa.getEmpresa(idEmpresaDefault)
                    if (empresa) setEmpresaLocal({
                        label: empresa.nomeEmpresa,
                        value: empresa.idEmpresa,
                        empresa: empresa
                    })
                } catch (error) { }
            }
        })()
        // eslint-disable-next-line
    }, [idEmpresaDefault])

    useEffect(() => {
        const delayTypingInputSearch = setTimeout(async () => {
            await getEmpresas(true);
        }, 500)

        setLoading(true)
        return () => clearTimeout(delayTypingInputSearch)

        // eslint-disable-next-line
    }, [searchText])

    return (
        <CustomPanel hiddePanel={hiddePanel}>
            <SelectPicker
                {...rest}
                data={empresas}
                virtualized
                block
                placeholder={placeHolder || 'Selecione a empresa'}
                renderValue={renderValue}
                renderMenu={renderMenu}
                listProps={{ onItemsRendered: onItemsEmpresaRendered }}
                searchBy={() => true}
                onSearch={setSearchText}
                defaultValue={idEmpresaDefault}
                onOpen={handleOpen}
                onClean={handleClean}
                onSelect={handleSelect}
            />
        </CustomPanel>
    )
}

SelectEmpresa.propTypes = {
    showError: PropTypes.func,
    onSelectEmpresa: PropTypes.func,
    placeHolder: PropTypes.string,
    hiddePanel: PropTypes.bool
}

export default SelectEmpresa
