import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Routes } from 'react-router-dom';

import { AuthProvider } from './contexts/authProvider';
import { EmpresaProvider } from './contexts/empresaProvider';

import createOpenRoutes from './routes/OpenRoutes';
import createPrivateRoutes from './routes/PrivateRoutes';

const App = () => {

  const queryClient = new QueryClient();

  const openRoutes = createOpenRoutes();
  const privateRoutes = createPrivateRoutes();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <EmpresaProvider>
          <AuthProvider>
            <Routes>
              {openRoutes}
              {privateRoutes}
            </Routes>
          </AuthProvider>
        </EmpresaProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App;