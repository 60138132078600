const CustomCellNumber = ({ rowData, col, decimal }) => {
    const className = "estatistica-cnae-custom-cell-number "
        + (col.dataKey.includes('clientes')
            ? "clientes"
            : col.dataKey.includes('encerrou')
                ? 'encerradas'
                : col.dataKey.includes('iniciou')
                    ? 'abertas'
                    : "empresas"
        )

    return (
        <div className={className}>
            {!rowData[col.dataKey]
                ? ''
                : decimal
                    ? rowData[col.dataKey]
                    : parseInt(rowData[col.dataKey]).toLocaleString('pt-BR')
            }
        </div>
    )
}

const defaultColumns = () => ([
    {
        dataKey: 'qtde_clientes',
        headerCell: <div className="header-clientes">Qtde. Clientes</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_prospectar',
        headerCell: <div className="header-empresas">Empresas a Prospectar</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'evolucao_empresas',
        headerCell: <div className="header-empresas">evolução nos últimos 5 anos</div>,
        width: 110,
        sortable: true,
        customCellContent: (rowData, col) => (
            <div className={`estatistica-cnae-custom-cell-number ${rowData[col.dataKey] < 0 ? 'negativo' : 'positivo'}`}>
                {!rowData[col.dataKey]
                    ? ''
                    : `${rowData[col.dataKey]}%`
                }
            </div>
        )
    }, {
        dataKey: 'qtde_simples_clientes',
        headerCell: <div className="header-clientes">Clientes Simples</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_mei_clientes',
        headerCell: <div className="header-clientes">Clientes MEI</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_outras_trib_clientes',
        headerCell: <div className="header-clientes">Clientes Outras Tribut.</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'media_capital_social_clientes',
        headerCell: <div className="header-clientes">Capital Médio Clientes (R$)</div>,
        width: 150,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'media_idade_clientes',
        headerCell: <div className="header-clientes">Idade Média Clientes</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} decimal={true} />
    }, {
        dataKey: 'qtde_empresas',
        headerCell: <div className="header-empresas">Qtde. Empresas</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_simples_empresas',
        headerCell: <div className="header-empresas">Empresas Simples</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_mei_empresas',
        headerCell: <div className="header-empresas">Empresas MEI</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_outras_trib_empresas',
        headerCell: <div className="header-empresas">Empresas Outras Tribut.</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'media_capital_social_empresas',
        headerCell: <div className="header-empresas">Capital Médio Empresas (R$)</div>,
        width: 150,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'media_idade_empresas',
        headerCell: <div className="header-empresas">Idade Média Empresas</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} decimal={true} />
    }, {
        dataKey: 'qtde_iniciou_ano_0',
        headerCell: <div className="header-abertas">Empresas Abertas em {(new Date()).getFullYear()}</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_iniciou_ano_1',
        headerCell: <div className="header-abertas">Empresas Abertas em {(new Date()).getFullYear() - 1}</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_iniciou_ano_2',
        headerCell: <div className="header-abertas">Empresas Abertas em {(new Date()).getFullYear() - 2}</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_iniciou_ano_3',
        headerCell: <div className="header-abertas">Empresas Abertas em {(new Date()).getFullYear() - 3}</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_iniciou_ano_4',
        headerCell: <div className="header-abertas">Empresas Abertas em {(new Date()).getFullYear() - 4}</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_iniciou_ano_5',
        headerCell: <div className="header-abertas">Empresas Abertas em {(new Date()).getFullYear() - 5}</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_encerrou_ano_0',
        headerCell: <div className="header-encerradas">Empresas Encerradas em {(new Date()).getFullYear()}</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_encerrou_ano_1',
        headerCell: <div className="header-encerradas">Empresas Encerradas em {(new Date()).getFullYear() - 1}</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_encerrou_ano_2',
        headerCell: <div className="header-encerradas">Empresas Encerradas em {(new Date()).getFullYear() - 2}</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_encerrou_ano_3',
        headerCell: <div className="header-encerradas">Empresas Encerradas em {(new Date()).getFullYear() - 3}</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_encerrou_ano_4',
        headerCell: <div className="header-encerradas">Empresas Encerradas em {(new Date()).getFullYear() - 4}</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }, {
        dataKey: 'qtde_encerrou_ano_5',
        headerCell: <div className="header-encerradas">Empresas Encerradas em {(new Date()).getFullYear() - 5}</div>,
        width: 90,
        sortable: true,
        customCellContent: (rowData, col) => <CustomCellNumber rowData={rowData} col={col} />
    }
])

const cnaeColumns = [
    {
        dataKey: 'codigo_cnae',
        headerCell: 'CNAE',
        width: 80,
        sortable: true
    }, {
        dataKey: 'descricao',
        headerCell: 'Descrição do CNAE',
        width: 400,
        sortable: true,
        fullText: true
    }]

const ufColumns = [
    {
        dataKey: 'uf',
        headerCell: 'UF',
        width: 60,
        sortable: true
    }, {
        dataKey: 'descricao',
        headerCell: 'Nome da UF',
        width: 250,
        sortable: true,
        fullText: true
    }]

const getDataTableColumnsCNAE = () => {
    const padrao = defaultColumns()

    const columns = [
        ...cnaeColumns,
        ...padrao
    ]

    return { columns }
}

const getDataTableColumnsUF = () => {
    const padrao = defaultColumns()

    const columns = [
        ...ufColumns,
        ...padrao
    ]

    return { columns }
}

export {
    getDataTableColumnsCNAE,
    getDataTableColumnsUF
}

