import api from '../services/api.js';

const resource = '/clientes'

const getClientes = async ({ idEmpresa, pageNumber, itemsPerPage, sortColumn, sortType, searchText }) => {
    const response = await api.get(resource, {
        params: {
            idEmpresa,
            pageNumber,
            itemsPerPage,
            sortColumn,
            sortType,
            searchText
        }
    })
    return response.data;
}

const getCliente = async id => {
    const response = await api.get(`${resource}/${id}`)
    return response.data;
}

const getDataRFB = async cnpj => {
    const response = await api.get(`${resource}/cnpj/${cnpj}`)
    return response.data;
}

const saveCliente = cliente => {
    if (!cliente.idCliente) {
        return api.post(resource, cliente)
    } else {
        return api.patch(`${resource}/${cliente.idCliente}`, cliente)
    }
}

const deleteCliente = id => api.delete(`${resource}/${id}`)

const getCountClientes = async idEmpresa => {
    const response = await api.get(`${resource}/count`, { params: { idEmpresa } })
    return response.data
}

const checkupCliente = async idEmpresa => {
    const response = await api.get(`${resource}/checkup`, { params: { idEmpresa } })
    return response.data
}

export const useApiCliente = () => ({
    getClientes,
    getCliente,
    saveCliente,
    deleteCliente,
    getDataRFB,
    getCountClientes,
    checkupCliente
})