import api from '../services/api.js';

const login = async (user, password) => {
    const response = await api.post('/auth/login', {
        user,
        password
    })
    return response.data;
}

const logout = async () => {
    await api.post(`/auth/logout`);
    return true
}

const validate = async () => {
    const response = await api.post('/auth/validate');
    return response.data;
}

const recovery = async email => {
    const response = await api.post('/auth/recovery', { email })
    return response.data;
}

export const useApiAuth = () => ({
    login,
    logout,
    validate,
    recovery
})