import { useNavigate } from "react-router-dom"
import { Button, Container, Panel, Stack } from "rsuite"

const PageNotFound = () => {

  const navigate = useNavigate()

  return (
    <Container>
      <Panel className="data-panel" style={{ marginTop: 75, padding: '20px 0' }}>
        <Stack direction="column" alignItems='center' spacing={40}>
          <img src='/error404.svg' alt='page not found' style={{ maxWidth: '100%', height: 450 }} />
          <h3>Ops… Você acabou de encontrar uma página de erro</h3>
          <h5>Lamentamos mas a página que procura não foi encontrada</h5>
          <Button color='blue' appearance="ghost" onClick={() => navigate('/')} >Voltar para a Home</Button>
        </Stack>
      </Panel>
    </Container>
  )
}

export default PageNotFound
