import { useContext, useEffect, useState } from "react";
import { Col, DatePicker, FlexboxGrid, Form, InputNumber, Stack, useToaster } from "rsuite";

import Confirm from "../../components/Confirm";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/ShowMessage";

import { EmpresaContext } from "../../contexts/empresaContext";
import { useApiCredito } from "../../hooks/useApiCredito";
import { dateFromString } from "../../services/utils";

const Credito = () => {
    const { empresaId } = useContext(EmpresaContext)

    const apiCredito = useApiCredito();

    const [creditos, setCreditos] = useState({});
    const [credito, setCredito] = useState({});

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [deleteMode, setDeleteMode] = useState(false);

    const getCreditos = async () => {
        setLoading(true)
        if (!empresaId || !dataTableParams) {
            setCreditos({});
        } else {
            try {

                const data = await apiCredito.getCreditos({ ...dataTableParams, idEmpresa: empresaId })
                setCreditos({
                    data: data.rows,
                    total: data.total,
                    filtered: data.filtered
                });
            } catch (error) {
                setCreditos({});
                showError(error)
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        (async () => await getCreditos())()
        // eslint-disable-next-line
    }, [empresaId, dataTableParams])

    const getCredito = async id => {
        try {
            const data = await apiCredito.getCredito(id)
            return {
                idEmpresa: data.idEmpresa,
                idCredito: data.idCredito,
                dataCredito: dateFromString(data.dataCredito),
                qtdeCredito: data.qtdeCredito
            }
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNewButton = () => {
        setCredito({
            idEmpresa: empresaId,
            idCredito: null,
            dataCredito: new Date(),
            qtdeCredito: 0
        })
        setDeleteMode(false)
        setOpenEditModal(true);
    }
    const handleClickEditButton = async id => {
        const data = await getCredito(id)
        if (data) {
            setCredito(data)
            setDeleteMode(false);
            setOpenEditModal(true);
        }
    }
    const handleClickDeleteButton = async id => {
        const data = await getCredito(id)
        if (data) {
            setCredito(data)
            setDeleteMode(true);
            setOpenEditModal(true);
        }
    }
    const handleClickCancelButton = () => {
        setOpenEditModal(false)
        setWaiting(false)
    }

    const handleClickYesButton = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = deleteMode ? await apiCredito.deleteCredito(credito.idCredito) : await apiCredito.saveCredito(credito)

            showSucess()
            handleClickCancelButton()
            await getCreditos()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idCredito' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, successMessage: `${deleteMode ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, errorObject: error, executeFirst: () => setWaiting(false) })

    const dataTableColumns = {
        editButtonColumn: {
            dataKey: 'idCredito',
            onClick: handleClickEditButton
        },
        deleteButtonColumn: {
            dataKey: 'idCredito',
            onClick: handleClickDeleteButton
        },
        columns: [
            {
                dataKey: 'dataCredito',
                headerCell: 'Data',
                minWidth: 100,
                sortable: true,
                customCellContent: (rowData, col) => dateFromString(rowData[col.dataKey]).toLocaleDateString('pt-BR')
            }, {
                dataKey: 'qtdeCredito',
                headerCell: 'Qtde.',
                align: 'right',
                width: 100,
                sortable: true,
                customCellContent: (rowData, col) => parseInt(rowData[col.dataKey]).toLocaleString('pt-BR')
            }, {
                dataKey: 'nomeUsuario',
                headerCell: 'Quem concedeu o crédito',
                flexGrow: 1,
                fullText: true,
                sortable: true,
            }
        ]
    }

    return (
        <Stack direction="column" alignItems={null}>
            <div className='datatable-title'>Cadastro de Créditos</div>

            <DataTable
                data={creditos}
                dataTableColumns={dataTableColumns}
                placeholderSearch='data, quantidade'
                textNewButton='Adicionar Crédito'
                onClickNewButton={handleClickNewButton}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
            />

            <DataModal
                title='Cadastro de credito'
                open={openEditModal}
                sizeModal='md'
                waiting={waiting}
                textSubmitButton={deleteMode ? 'Excluir' : 'Gravar'}
                colorSubmitButton={deleteMode ? 'red' : 'green'}
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={handleClickCancelButton}
                checkFormValidation={!deleteMode}
            >
                <FlexboxGrid className="flex-grid-form" align="top" justify="space-between">

                    <FlexboxGrid.Item as={Col} colspan={24} lg={8}>
                        <Form.Group controlId="idCredito">
                            <Form.ControlLabel>Código</Form.ControlLabel>
                            <Form.Control
                                name="idCredito"
                                readOnly
                                placeholder='gerado automaticamente'
                                value={credito.idCredito || ''}
                            />
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={8} >
                        <Form.Group controlId="dataCredito">
                            <Form.ControlLabel>Data</Form.ControlLabel>
                            <Form.Control
                                readOnly={deleteMode}
                                name="dataCredito"
                                accepter={DatePicker}
                                block
                                oneTap
                                format="dd/MM/yyyy"
                                placeholder='data do crédito'
                                value={credito.dataCredito}
                                onChange={value => setCredito({ ...credito, dataCredito: value })}
                            />
                        </Form.Group>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item as={Col} colspan={24} lg={8}>
                        <Form.Group controlId='qtdeCredito'>
                            <Form.ControlLabel>Quantidade</Form.ControlLabel>
                            <Form.Control
                                name="qtdeCredito"
                                readOnly={deleteMode}
                                accepter={InputNumber}
                                min={0}
                                value={credito.qtdeCredito}
                                onChange={value => setCredito({ ...credito, qtdeCredito: value })}
                            />
                        </Form.Group>
                    </FlexboxGrid.Item>

                </FlexboxGrid>
            </DataModal>
            <Confirm
                open={openConfirm}
                message={`Confirma a ${deleteMode ? 'exclusão' : 'gravação'} dos dados do crédito?`}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYesButton}
            />
        </Stack >
    )
}

export default Credito