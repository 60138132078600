import PropTypes from 'prop-types';
import { useEffect, useMemo, useReducer, useState } from 'react';

import { Avatar, Button, Input, InputGroup, Pagination, Panel, Stack, Table } from 'rsuite';

import EditIcon from '@rsuite/icons/Edit';
import PlusIcon from '@rsuite/icons/Plus';
import SearchIcon from '@rsuite/icons/Search';
import TrashIcon from '@rsuite/icons/Trash';

import './style.css';

const { ColumnGroup, Column, HeaderCell, Cell } = Table

const DataTableColumn = col => (
    <Column
        key={col.dataKey}
        minWidth={col.minWidth}
        width={col.width}
        flexGrow={col.flexGrow}
        align={col.align || 'left'}
        sortable={col.sortable}
        fullText={col.fullText}
    >
        <HeaderCell style={col.headerStyle} className={col.headerClassName}>
            {
                typeof col.headerCell === 'function'
                    ? <col.headerCell />
                    : col.headerCell
            }
        </HeaderCell>
        {
            col.customCell
                ? <col.customCell dataKey={col.dataKey} />
                : col.customCellContent
                    ? <Cell dataKey={col.dataKey} style={col.style}>
                        {rowData => col.customCellContent(rowData, col)}
                    </Cell>
                    : col.avatar
                        ? <Cell dataKey={col.dataKey} style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {rowData => col.avatar && <Avatar size="md" circle src={rowData[col.dataKey]} />}
                        </Cell>
                        : <Cell dataKey={col.dataKey} style={col.style} />
        }
    </Column>
)

const DefaultHeader = ({
    hiddeNewButton,
    onClickNewButton,
    textNewButton,
    ExtraButtons,
    setInputValue,
    placeholderSearch,
    subtitle
}) => {

    const multiExtraButtons = Array.isArray(ExtraButtons);

    return (
        <Stack style={{ marginBottom: 10 }} justifyContent={hiddeNewButton && !subtitle ? 'flex-end' : 'space-between'}>
            <Stack spacing={10}>
                {!hiddeNewButton &&
                    <Button appearance='ghost' color='green' size='sm' onClick={onClickNewButton}>
                        <Stack spacing={7}>
                            <PlusIcon />
                            {textNewButton || 'Novo'}
                        </Stack>
                    </Button>
                }
                {multiExtraButtons
                    ? ExtraButtons.map((botao, index) => <div key={index}>{botao}</div>)
                    : ExtraButtons
                }
                {subtitle &&
                    <div className='datatable-subtitle' >
                        {subtitle}
                    </div>
                }

            </Stack>
            <Stack spacing={10} justifyContent='flex-end'>
                Pesquisar
                <InputGroup inside style={{ width: 300 }}>
                    <Input type='search' onChange={(value, e) => setInputValue(value)} placeholder={placeholderSearch} />
                    <InputGroup.Addon>
                        <SearchIcon />
                    </InputGroup.Addon>
                </InputGroup>
            </Stack>
        </Stack>
    )
}

// extraButtons para enviar 1 ou um array de botões para serem renderizados na tela, após o botão "newButton"

const DataTable = ({
    data,
    dataTableColumns,
    title,
    subtitle,
    placeholderSearch,
    hiddeNewButton,
    textNewButton,
    onClickNewButton,
    loading,
    setLoading,
    goToLastPage,
    setGoToLastPage,
    onChangeParams,
    minHeight,
    ExtraButtons,
    CustomHeader,
    defaultItemsPerPage,
    hideBorder,
    hidePagination,
    ...rest
}) => {

    const { editButtonColumn, deleteButtonColumn, columns } = dataTableColumns;

    const dataTableParamCompare = (prevState, newState) => {
        if (prevState.searchText === newState.searchText &&
            prevState.pageNumber === newState.pageNumber &&
            prevState.itemsPerPage === newState.itemsPerPage &&
            prevState.sortColumn === newState.sortColumn &&
            prevState.sortType === newState.sortType) {
            return prevState
        } else {
            return newState
        }
    }

    //Só retorna o estado atualizado se realmente ouve alteração (isso por ser objeto)
    const [dataTableParams, setDataTableParams] = useReducer(dataTableParamCompare, {
        searchText: '',
        pageNumber: 1,
        itemsPerPage: defaultItemsPerPage || 10,
        sortColumn: undefined,
        sortType: undefined
    });

    const [inputValue, setInputValue] = useState('');
    const [footerText, setFooterText] = useState('');

    const handleChangePage = page => setDataTableParams({ ...dataTableParams, pageNumber: page, });
    const handleChangeLimit = limitValue => setDataTableParams({ ...dataTableParams, pageNumber: 1, itemsPerPage: limitValue })
    const handleSortColumn = (sortColumn, sortType) => setDataTableParams({ ...dataTableParams, sortColumn: sortColumn, sortType: sortType });

    useEffect(() => {
        if (data.filtered) {
            const totalPages = Math.ceil(data.filtered / dataTableParams.itemsPerPage)
            if (goToLastPage) {
                setDataTableParams({ ...dataTableParams, pageNumber: totalPages, });
                if (setGoToLastPage) setGoToLastPage(false)
            } else if (totalPages < dataTableParams.pageNumber) {
                setDataTableParams({ ...dataTableParams, pageNumber: totalPages, });
            }
        }

    }, [data.filtered, goToLastPage, setGoToLastPage, dataTableParams])

    useEffect(() => {
        const delayTypingInputSearch = setTimeout(() => {
            setDataTableParams({ ...dataTableParams, searchText: inputValue, pageNumber: 1 })
        }, 500)

        setFooterText('')
        if (setLoading) setLoading(true)
        return () => clearTimeout(delayTypingInputSearch)

        // eslint-disable-next-line
    }, [inputValue])

    //Paga obter as alterações dos valores que utilizam na pesquisa
    useEffect(() => {
        if (onChangeParams) {
            onChangeParams(dataTableParams)
        }

        // eslint-disable-next-line
    }, [dataTableParams])

    const createFooterText = () => {
        if (!data.total) {
            setFooterText('')
            return
        }

        if (data.filtered === 0) {
            const pluralize = data.total > 1 ? 's' : ''
            setFooterText(`Mostrando nenhum do${pluralize} ${data.total.toLocaleString('pt-BR')} registro${pluralize}`)
        } else {
            const filtrado = data.filtered === data.total ? '' : `(filtrados de ${data.total.toLocaleString('pt-BR')})`
            const ini = ((dataTableParams.pageNumber - 1) * dataTableParams.itemsPerPage) + 1
            let fim = dataTableParams.pageNumber * dataTableParams.itemsPerPage

            if (fim > data.filtered) fim = data.filtered

            setFooterText(`Mostrando ${ini.toLocaleString('pt-BR')} até ${fim.toLocaleString('pt-BR')} de ${data.filtered.toLocaleString('pt-BR')} ${filtrado}`)
        }
    }

    // eslint-disable-next-line
    useMemo(() => createFooterText(), [data.filtered, dataTableParams.pageNumber, dataTableParams.itemsPerPage]);

    return (
        <Stack direction='column' alignItems={null}>
            {title &&
                <div className='datatable-title'>
                    {title}
                </div>
            }

            <Panel
                bordered={hideBorder ? false : true}
                shaded
                bodyFill={hideBorder ? true : false}
                className='data-panel'
            >
                {
                    CustomHeader
                        ? CustomHeader
                        : <DefaultHeader
                            hiddeNewButton={hiddeNewButton}
                            onClickNewButton={onClickNewButton}
                            textNewButton={textNewButton}
                            ExtraButtons={ExtraButtons}
                            setInputValue={setInputValue}
                            placeholderSearch={placeholderSearch}
                            subtitle={subtitle}
                        />
                }

                <Table
                    data={data.data}
                    sortColumn={dataTableParams.sortColumn}
                    sortType={dataTableParams.sortType}
                    onSortColumn={handleSortColumn}
                    loading={loading}
                    minHeight={minHeight || 500}
                    hover
                    autoHeight
                    bordered
                    headerHeight={columns.find(c => c.columnGroup) ? 80 : 40}
                    {...rest}
                >
                    {
                        columns.map(col => (
                            col.columnGroup
                                ? <ColumnGroup header={col.columnGroup} align='center' key={col.columnGroup}>
                                    {col.columns.map(c => (!c.hidden ? DataTableColumn(c) : <></>))}
                                </ColumnGroup>
                                : !col.hidden ? DataTableColumn(col) : null
                        ))
                    }
                    {editButtonColumn &&
                        <Column width={45} fixed='right'>
                            <HeaderCell></HeaderCell>
                            <Cell style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {rowData =>
                                    <Button appearance='ghost' color='blue' size='sm' onClick={() => editButtonColumn.onClick(rowData[editButtonColumn.dataKey])}>
                                        <EditIcon />
                                    </Button>
                                }
                            </Cell>
                        </Column>
                    }
                    {deleteButtonColumn &&
                        <Column width={45} fixed='right'>
                            <HeaderCell></HeaderCell>
                            <Cell style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {rowData =>
                                    ((!deleteButtonColumn.hidden) ||
                                        (deleteButtonColumn.hidden && !deleteButtonColumn.hidden(rowData))) &&
                                    <Button appearance='ghost' color='red' size='sm' onClick={() => deleteButtonColumn.onClick(rowData[deleteButtonColumn.dataKey])}>
                                        <TrashIcon />
                                    </Button>
                                }
                            </Cell>
                        </Column>
                    }
                </Table>

                {!hidePagination &&
                    <div style={{ paddingTop: 20 }}>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={3}
                            size="sm"
                            layout={[footerText, '-', 'limit', '|', 'pager', 'skip']}
                            total={data.filtered}
                            limitOptions={[5, 10, 30, 50]}
                            limit={dataTableParams.itemsPerPage}
                            activePage={dataTableParams.pageNumber}
                            onChangePage={handleChangePage}
                            onChangeLimit={handleChangeLimit}
                        />
                    </div>
                }
            </Panel>
        </Stack>
    )
}

DataTable.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    dataTableColumns: PropTypes.object,
    title: PropTypes.string,
    placeholderSearch: PropTypes.string,
    hiddeNewButton: PropTypes.bool,
    textNewButton: PropTypes.string,
    onClickNewButton: PropTypes.func,
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
    onChangeParams: PropTypes.func,
    goToLastPage: PropTypes.bool,
    minHeight: PropTypes.number
}

export default DataTable
