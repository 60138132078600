import api from '../services/api.js';

const resource = '/leadsenviar'

const getLeads = async ({ idLeads, idEmpresa, pageNumber, itemsPerPage, sortColumn, sortType, razao_social, municipio, uf, codigo_cnae, descricao_cnae,
    capital_social_inicial, capital_social_final, data_abertura_inicial, data_abertura_final, exibir_marcado, exibir_nao_marcado, exibir_enviado }) => {
    const response = await api.get(`${resource}/list`, {
        params: {
            idLeads,
            idEmpresa,
            pageNumber,
            itemsPerPage,
            sortColumn,
            sortType,
            razao_social,
            municipio,
            uf,
            codigo_cnae,
            descricao_cnae,
            capital_social_inicial,
            capital_social_final,
            data_abertura_inicial,
            data_abertura_final,
            exibir_enviado,
            exibir_marcado,
            exibir_nao_marcado
        }
    })
    return response.data;
}

const checkLead = id => {
    return api.patch(`${resource}/check`, { id: id })
}

const checkAllLead = ({ idLeads, idEmpresa, razao_social, municipio, uf, codigo_cnae, descricao_cnae,
    capital_social_inicial, capital_social_final, data_abertura_inicial, data_abertura_final, checked }) => {
    return api.patch(`${resource}/checkall`, {
        idLeads,
        idEmpresa,
        checked,
        razao_social,
        municipio,
        uf,
        codigo_cnae,
        descricao_cnae,
        capital_social_inicial,
        capital_social_final,
        data_abertura_inicial,
        data_abertura_final
    })
}

const sendLead = (idLeads, idEmpresa) => {
    return api.post(`${resource}/send`, { idLeads: idLeads, idEmpresa: idEmpresa })
}

export const useApiLeadsEnvia = () => ({
    getLeads,
    checkLead,
    checkAllLead,
    sendLead
})