import api from '../services/api.js';

const resource = '/perfil'

const saveDarkMode = darkMode => api.patch(`${resource}/darkmode`, { darkMode: darkMode })
const savePerfil = user => api.patch(resource, user)
const changePassword = async ({ password, newPassword, confirmPassword }) => {
    const response = await api.patch(`${resource}/changepassword`, { password, newPassword, confirmPassword });
    return response.data;
}

export const useApiPerfil = () => ({
    saveDarkMode,
    savePerfil,
    changePassword
})