import { useContext, useEffect, useState } from "react";
import { Stack, useToaster } from "rsuite";

import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/ShowMessage";

import { EmpresaContext } from "../../contexts/empresaContext";

import { useApiEstatisticas } from '../../hooks/useApiEstatisticas';

import CustomHeader from "./CustomHeader";
import { getDataTableColumnsCNAE, getDataTableColumnsUF } from "./dataTableColumns";

import './style.css';

const defaultFilterFields = {
    cnae: [],
    municipio: [],
    uf: [],
    faixaCapitalSocial: [],
    faixaIdadeEmpresa: [],
    exibirCnaeSemCliente: false,
    opcaoMei: 'T',
    opcaoSimples: 'T'
}

/*
    formatoDemonstracao:
    1 - CNAE / UF / Municipio
    2 - UF / Municipio / CNAE
*/

const Estatistica = () => {
    const { empresaId, setEmpresaReadOnly } = useContext(EmpresaContext)

    const apiEstatisticas = useApiEstatisticas()

    const [dadosPorCnae, setDadosPorCnae] = useState({})
    const [dadosPorUF, setDadosPorUF] = useState({})
    const [formatoDemonstracao, setFormatoDemonstracao] = useState(1)

    const [dataTableParams, setDataTableParams] = useState()
    const [filterParams, setFilterParams] = useState(defaultFilterFields)
    const [filterFields, setFilterFields] = useState(defaultFilterFields)

    const [loading, setLoading] = useState(true)

    const getEstatisticas = async () => {
        setLoading(true)
        setEmpresaReadOnly(true)
        if (!empresaId || !dataTableParams) {
            setDadosPorCnae({})
            setDadosPorUF({})
        } else {
            try {
                if (formatoDemonstracao === 1) {
                    const data = await apiEstatisticas.getByCnae({ ...dataTableParams, ...filterParams, idEmpresa: empresaId })
                    setDadosPorCnae({
                        data: data.rows,
                        total: data.total,
                        filtered: data.filtered
                    })
                    setDadosPorUF({})
                } else {
                    const data = await apiEstatisticas.getByUF({ ...dataTableParams, ...filterParams, idEmpresa: empresaId })
                    setDadosPorUF({
                        data: data.rows,
                        total: data.total,
                        filtered: data.filtered
                    })
                    setDadosPorCnae({})
                }
            } catch (error) {
                setDadosPorCnae({})
                setDadosPorUF({})
                showError(error)
            }
        }
        setLoading(false)
        setEmpresaReadOnly(false)
    }

    useEffect(() => {
        (async () => await getEstatisticas())()
        // eslint-disable-next-line
    }, [empresaId, dataTableParams, filterParams, formatoDemonstracao])

    const handleClickApplyFilter = () => {
        setFilterParams(filterFields)
    }

    const handleClickClearFilter = () => {
        setFilterParams(defaultFilterFields)
        setFilterFields(defaultFilterFields)
    }

    const toaster = useToaster();
    const showError = error => showMessage({ toaster, errorObject: error })

    const dataTableColumns = formatoDemonstracao === 1 ? getDataTableColumnsCNAE() : getDataTableColumnsUF()

    return (
        <Stack direction="column" alignItems={null}>
            <div className='datatable-title'>Estatística</div>
            <DataTable
                data={formatoDemonstracao === 1 ? dadosPorCnae : dadosPorUF}
                dataTableColumns={dataTableColumns}
                placeholderSearch='cnae'
                loading={loading}
                className={'estatistica-cnae-table'}
                headerHeight={80}
                rowHeight={38}
                minHeight={475}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                CustomHeader={<CustomHeader
                    loading={loading}
                    filterFields={filterFields}
                    setFilterFields={setFilterFields}
                    formatoDemonstracao={formatoDemonstracao}
                    setFormatoDemonstracao={setFormatoDemonstracao}
                    onClickApplyFilter={handleClickApplyFilter}
                    onClickClearFilter={handleClickClearFilter}
                />}
            />
        </Stack >
    )
}

export default Estatistica