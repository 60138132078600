import api from '../services/api.js';

const resource = '/empresas'

const getEmpresas = async ({ start, pageNumber, itemsPerPage, sortColumn, sortType, searchText }) => {
    const response = await api.get(resource, {
        params: {
            start,
            pageNumber,
            itemsPerPage,
            sortColumn,
            sortType,
            searchText
        }
    });
    return response.data;
}

const getEmpresa = async id => {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
}

const saveEmpresa = empresa => {
    if (!empresa.idEmpresa) {
        return api.post(resource, empresa)
    } else {
        return api.patch(`${resource}/${empresa.idEmpresa}`, empresa)
    }
}

const deleteEmpresa = id => api.delete(`${resource}/${id}`)

export const useApiEmpresa = () => ({
    getEmpresas,
    getEmpresa,
    saveEmpresa,
    deleteEmpresa
})