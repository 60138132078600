import { Col, FlexboxGrid } from "rsuite";

import './style.css';

const DadosField = ({ label, value, size, style, styleContainer }) => {
    return (
        <FlexboxGrid.Item
            as={Col}
            lg={(size && size.lg) || 24}
            md={(size && size.md) || 24}
            sm={(size && size.sm) || 24}
            style={styleContainer || null}
        >
            <div className="field-container">
                <label>{label}</label>
                <div className="field-value" style={style || null}>
                    {value}
                </div>
            </div>
        </FlexboxGrid.Item>
    )
}

export default DadosField