import { useState } from "react";
import { useQuery } from "react-query";
import { Col, FlexboxGrid, Panel, Progress, Row, Stack, Table } from "rsuite";
import StackItem from "rsuite/esm/Stack/StackItem";

import CalendarIcon from '@rsuite/icons/Calendar';
import CloudReflashIcon from '@rsuite/icons/CloudReflash';
import HistoryIcon from '@rsuite/icons/History';

import Title from "../../components/Title";

import { useApiDownload } from "../../hooks/useApiDownload";

import './style.css';

const { Column, HeaderCell, Cell } = Table;

const CompactCell = props => <Cell {...props} style={{ padding: '4px 4px 4px 8px' }} />;

const ProgressCell = ({ rowData, type, ...props }) => {
    const total = parseFloat(rowData['total']) || 0;
    const atual = parseFloat(rowData['atual']) || 0;
    const status = rowData['status'];

    const perc = status === 2 ? 0
        : status === 3 ? 100
            : isNaN(total) || total === 0 ? 0 : Math.round(atual / total * 100);

    const statusPerc = perc >= 100
        ? "success"
        : perc === 0 ? null : "active"

    return (
        <CompactCell {...props}>
            <Stack>
                <StackItem style={{ width: '100%' }}>
                    <Progress.Line className="progress" percent={perc} status={statusPerc} />
                </StackItem>
                {status === 1 &&
                    <div className="column-values">
                        <span>
                            {atual.toLocaleString('pt-BR')}/{total.toLocaleString('pt-BR')}
                        </span>
                        <span>{type || 'bytes'}</span>
                    </div>
                }
            </Stack>
        </CompactCell>
    )
}

const HeaderPanel = ({ arrayData, title }) => {
    return (
        <div>
            {`${title} ${!arrayData.length ? '' : `(concluídos ${arrayData.filter(c => c.status === 3).length} de ${arrayData.length})`}`}
        </div>
    )
}

const BoxGridPanel = ({ arrayData, title, type }) => {
    return (
        <FlexboxGrid.Item as={Col} colspan={24} lg={24} md={24} className="panel-container">
            <Panel header={<HeaderPanel title={title} arrayData={arrayData} />}>
                <Table
                    data={arrayData}
                    bordered
                    cellBordered
                    height={180}
                    headerHeight={0}
                    rowHeight={30}
                >
                    <Column width={140} fixed verticalAlign='middle'>
                        <HeaderCell />
                        <CompactCell dataKey="arquivo" />
                    </Column>
                    <Column flexGrow={1}>
                        <HeaderCell />
                        <ProgressCell type={type} />
                    </Column>
                </Table>
            </Panel>
        </FlexboxGrid.Item>
    )
}

const Calendar = () => <CalendarIcon style={{ fontSize: '3em', marginRight: 10 }} />;
const Time = () => <HistoryIcon style={{ fontSize: '3em', marginRight: 10 }} />;
const Updated = () => <CloudReflashIcon style={{ fontSize: '3em', marginRight: 10 }} />;

const Download = () => {
    const apiDownload = useApiDownload();

    const [download, setDownLoad] = useState([]);
    const [unzip, setUnzip] = useState([]);
    const [importacao, setImportacao] = useState([]);

    const [dataArquivo, setDataArquivo] = useState();
    const [ultimaVerificacao, setUltimaVerificacao] = useState();
    const [ultimaAtualizacao, setUltimaAtualizacao] = useState();

    useQuery(["download-rfb"], () => {
        apiDownload.getDownloads()
            .then(data => {
                setDownLoad(data.processos.download);
                setUnzip(data.processos.unzip);
                setImportacao(data.processos.importacao);

                setDataArquivo(data.dataArquivo);
                setUltimaVerificacao(data.ultimaVerificacao);
                setUltimaAtualizacao(data.ultimaAtualizacao);
            })
            .catch(error => {
                console.log(error);
            })
    }, {
        refetchInterval: 3000
    })

    return (
        <div className="download-page">
            <Title text="Painel de Atualização dos Arquivos da RFB" />
            <Row className="panel-container update-info">
                <Col xs={6}>
                    <Panel className="panel-info bg-gradient-orange">
                        <Calendar />
                        <div>
                            <div className="title">Data dos arquivos na RFB</div>
                            <div className="value">{dataArquivo}</div>
                        </div>
                    </Panel>
                </Col>
                <Col xs={6}>
                    <Panel className="panel-info bg-gradient-green">
                        <Updated />
                        <div>
                            <div className="title">Atualização da base do Leads</div>
                            <div className="value">{ultimaAtualizacao}</div>
                        </div>
                    </Panel>
                </Col>
                <Col xs={6}>
                    <Panel className="panel-info bg-gradient-blue">
                        <Time />
                        <div>
                            <div className="title">Última verificação na RFB</div>
                            <div className="value">{ultimaVerificacao}</div>
                        </div>
                    </Panel>
                </Col>
            </Row>

            <FlexboxGrid>
                <BoxGridPanel title='Download' arrayData={download} />
                <BoxGridPanel title='Unzip' arrayData={unzip} />
                <BoxGridPanel title='Importação' arrayData={importacao} type='registros' />
            </FlexboxGrid>
        </div>
    )
}

export default Download
