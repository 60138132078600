import FileDownload from 'js-file-download';
import api from '../services/api.js';

const resource = '/leads'

const countLeads = async ({
    cnae, cidade, uf, cep, dataInicial, dataFinal, capitalSocial, socio, razaoSocial,
    opcaoSimples, opcaoMei, desconsiderarCliente, idEmpresa, socioTipoBusca, razaoSocialTipoBusca,
    considerarRecuperacaoJudicial, considerarCnaeSecundaria, deveConterTodosCnaes, qualificacaoSocio
}) => {
    const response = await api.get(`${resource}/count`, {
        params: {
            cnae, cidade, uf, cep, dataInicial, dataFinal, capitalSocial, socio, razaoSocial,
            opcaoSimples, opcaoMei, desconsiderarCliente, idEmpresa, socioTipoBusca, razaoSocialTipoBusca,
            considerarRecuperacaoJudicial, considerarCnaeSecundaria, deveConterTodosCnaes, qualificacaoSocio
        }
    });
    return response.data;
}

const getLeads = async ({
    cnae, cidade, uf, cep, dataInicial, dataFinal, capitalSocial, socio, razaoSocial,
    opcaoSimples, opcaoMei, desconsiderarCliente, idEmpresa, leadsCount, socioTipoBusca, razaoSocialTipoBusca,
    considerarRecuperacaoJudicial, considerarCnaeSecundaria, deveConterTodosCnaes, qualificacaoSocio
}) => {
    const response = await api.get(`${resource}/data`, {
        params: {
            cnae, cidade, uf, cep, dataInicial, dataFinal, capitalSocial, socio, razaoSocial,
            opcaoSimples, opcaoMei, desconsiderarCliente, idEmpresa, leadsCount, socioTipoBusca, razaoSocialTipoBusca,
            considerarRecuperacaoJudicial, considerarCnaeSecundaria, deveConterTodosCnaes, qualificacaoSocio
        }
    });
    return response.data;
}

const downloadLeads = async ({ idEmpresa, fileName }) => {
    const response = await api.get(`${resource}/download/${fileName}`, { params: { idEmpresa } });
    if (response.data) {
        FileDownload(response.data, fileName);
    }
}

const emailLeads = async ({ idEmpresa, fileName }) => {
    const response = await api.get(`${resource}/email/${fileName}`, { params: { idEmpresa } });
    return response.data;
}

const listLeads = async ({ start, itemsPerPage, idEmpresa, concluido }) => {
    const response = await api.get(`${resource}/list`, { params: { start, itemsPerPage, idEmpresa, concluido } });
    return response.data;
}
export const useApiLeads = () => ({
    countLeads,
    getLeads,
    downloadLeads,
    listLeads,
    emailLeads
})