import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonToolbar, Container, CustomProvider, Form, Loader, Panel, Stack, useToaster } from "rsuite";
import { ptBR } from "rsuite/esm/locales";
import { showMessage } from "../../components/ShowMessage";
import { useApiAuth } from "../../hooks/useApiAuth";
import { isEmail } from '../../services/utils';

import './style.css';

const RecuperaSenha = () => {
    const { recovery } = useApiAuth();

    const navigate = useNavigate();
    const toaster = useToaster();

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSend = async () => {
        if (loading) return false

        if (!isEmail(email)) return showMessage({ toaster, errorObject: { message: 'E-mail inválido.' }, placement: 'topCenter' })

        setLoading(true)

        try {
            await recovery(email);

            showMessage({ toaster, successMessage: 'E-mail enviado com sucesso.', placement: 'topCenter' });
            navigate('/login', { replace: true, state: null });
        } catch (error) {
            showMessage({ toaster, errorObject: error, placement: 'topCenter' });
        }

        setLoading(false)
    }

    return (
        <CustomProvider theme='light' locale={ptBR}>
            <Container className="container-recupera-senha">
                <Panel bordered shaded className="data-panel">

                    <div className="container-logo-recupera-senha" >
                        <img alt="logo" src='/logo-cash-up-leads.png' />
                    </div>

                    <h5 className="recupera-senha-title">Recuperação de Senha</h5>

                    <Form fluid>
                        <Form.Group controlId="email">
                            <Form.ControlLabel>Email</Form.ControlLabel>
                            <Form.Control
                                autoFocus
                                name="email"
                                type="email"
                                value={email}
                                onChange={setEmail}
                            />
                            <Form.HelpText>Informe o e-mail para enviarmos a nova senha.</Form.HelpText>
                        </Form.Group>

                        <Stack justifyContent="space-between" alignItems="flex-end">
                            <Form.Group>
                                <ButtonToolbar>
                                    <Button style={{ height: 40 }} appearance={loading ? "primary" : "ghost"} color={loading ? "yellow" : "blue"} onClick={handleSend}>
                                        {loading
                                            ?
                                            <Stack spacing={10} alignItems="center" justifyContent="center">
                                                <Loader />
                                                <div style={{ fontSize: 16 }}>Aguarde...</div>
                                            </Stack>
                                            : 'Enviar'}
                                    </Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Stack>

                    </Form>
                </Panel>
            </Container>
        </CustomProvider >
    )
}

export default RecuperaSenha
