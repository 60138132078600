import { useContext, useState } from "react";
import { Avatar, Button, Col, Form, Grid, InputGroup, Loader, Row, Stack, Uploader, useToaster } from "rsuite";

import CameraRetroIcon from '@rsuite/icons/legacy/CameraRetro';

import Confirm from "../../components/Confirm";
import DataModal from "../../components/DataModal";

import { showMessage } from "../../components/ShowMessage";
import { AuthContext } from "../../contexts/authContext";
import { notEmpty } from "../../services/utils";

const PerfilUsuario = ({ open, setOpen }) => {

    const { user, savePerfil } = useContext(AuthContext)

    const [usuario, setUsuario] = useState(user);

    const [waiting, setWaiting] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const handleClickYesButton = async () => {
        setOpenConfirm(false)
        setWaiting(true)

        const response = await savePerfil(usuario)
        if (response.status === 200) return showSucess();

        showError({ message: response && response.data ? response.data.message : response.message })
    }

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, successMessage: `Alteração concluída com sucesso`, executeFirst: () => setWaiting(false), executeLast: () => setOpen(false) })
    const showError = error => showMessage({ toaster, errorObject: error, executeFirst: () => setWaiting(false) })

    const previewFile = (file, callback) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }

    const [showErrorMessage, setShowErrorMessage] = useState({
        nome: false
    })

    const formValidation = {
        nome: {
            validation: (input) => notEmpty(input.value),
            messge: 'Nome é obrigatório',
        }
    }

    return (
        <>
            <DataModal
                title='Perfil do Usuário'
                open={open}
                sizeModal='lg'
                waiting={waiting}
                textSubmitButton='Confirmar'
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={() => setOpen(false)}
                checkFormValidation={true}
                formValidation={formValidation}
                setShowErrorMessage={setShowErrorMessage}
            >
                <Grid fluid style={{ marginTop: 30 }}>
                    <Row>
                        <Col xs={24} md={12}>
                            <Stack direction='column' alignItems='center'>
                                <Avatar className='avatar-perfil-300' src={usuario.foto} circle />
                                <Uploader
                                    action=''
                                    listType='picture'
                                    fileListVisible={false}
                                    accept="image/*"
                                    shouldUpload={file => {
                                        setUploading(true);
                                        previewFile(file.blobFile, value => {
                                            setUsuario({ ...usuario, foto: value });
                                            setUploading(false);
                                        });
                                        return false;
                                    }}
                                >
                                    <Button appearance='subtle'>
                                        {
                                            uploading
                                                ? <Loader backdrop center />
                                                : <CameraRetroIcon style={{ fontSize: '2em' }} />
                                        }
                                    </Button>
                                </Uploader>
                            </Stack>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Group controlId="email">
                                <Form.ControlLabel>Email</Form.ControlLabel>
                                <InputGroup>
                                    <InputGroup.Addon> @</InputGroup.Addon>
                                    <Form.Control
                                        name="email"
                                        type="email"
                                        readOnly={true}
                                        value={usuario.email}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="nome">
                                <Form.ControlLabel>Nome</Form.ControlLabel>
                                <Form.Control
                                    name="nome"
                                    autoFocus
                                    value={usuario.nome}
                                    onChange={value => setUsuario({ ...usuario, nome: value })}
                                />
                                <Form.ErrorMessage show={showErrorMessage.nome}>{formValidation.nome.messge}</Form.ErrorMessage>
                            </Form.Group>
                            <Form.Group controlId="cargo">
                                <Form.ControlLabel>Cargo</Form.ControlLabel>
                                <Form.Control
                                    name="cargo"
                                    value={usuario.cargo}
                                    onChange={value => setUsuario({ ...usuario, cargo: value })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </DataModal>

            <Confirm
                open={openConfirm}
                message={`Confirma a alteração dos dados do Usuário?`}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYesButton}
            />
        </>
    )
}

export default PerfilUsuario
