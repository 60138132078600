import api from '../services/api.js';

const resource = '/creditos'

const getCreditos = async ({ idEmpresa, pageNumber, itemsPerPage, sortColumn, sortType, searchText }) => {
    const response = await api.get(resource, {
        params: {
            idEmpresa,
            pageNumber,
            itemsPerPage,
            sortColumn,
            sortType,
            searchText
        }
    })
    return response.data;
}

const getCredito = async id => {
    const response = await api.get(`${resource}/${id}`)
    return response.data;
}

const saveCredito = credito => {
    if (!credito.idCredito) {
        return api.post(resource, credito)
    } else {
        return api.patch(`${resource}/${credito.idCredito}`, credito)
    }
}

const deleteCredito = id => api.delete(`${resource}/${id}`)

export const useApiCredito = () => ({
    getCreditos,
    getCredito,
    saveCredito,
    deleteCredito
})