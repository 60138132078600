import { useState } from "react";
import { Form, InputGroup, useToaster } from "rsuite";
import Confirm from "../../components/Confirm";
import DataModal from "../../components/DataModal";
import { showMessage } from "../../components/ShowMessage";
import { useApiPerfil } from "../../hooks/useApiPerfil";

import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';

const AlterarSenha = ({ open, setOpen }) => {

    const apiPerfil = useApiPerfil();

    const [waiting, setWaiting] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);

    const [password, setPassword] = useState('password');
    const [newPassword, setNewPassword] = useState('password');
    const [confirmPassword, setConfirmPassword] = useState('password');

    const [error, setError] = useState();

    const handleVisible1 = () => setVisible1(!visible1);
    const handleVisible2 = () => setVisible2(!visible2);
    const handleVisible3 = () => setVisible3(!visible3);

    const setDfaultValues = () => {
        setPassword('password');
        setNewPassword('password');
        setConfirmPassword('password');

        setVisible1(false);
        setVisible2(false);
        setVisible3(false);

        setError(null);

        setWaiting(false);
    }

    const handleClickSubmitButton = () => {
        if (newPassword === '') return setError(1);
        if (newPassword !== confirmPassword) return setError(2);

        setError(null);
        setOpenConfirm(true);
    }

    const handleClickCancelButton = () => {
        setDfaultValues();
        setOpen(false);
    }

    const handleClickYesButton = async () => {
        setOpenConfirm(false);
        setWaiting(true);

        try {
            await apiPerfil.changePassword({ password, newPassword, confirmPassword });
            showSucess();
        } catch (error) {
            showError(error)
        }
    }

    const handleExecuteLast = () => {
        setOpen(false);
        setDfaultValues();
    }

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, successMessage: `Senha alterada com sucesso`, executeFirst: () => setWaiting(false), executeLast: handleExecuteLast })
    const showError = error => showMessage({ toaster, errorObject: error, executeFirst: () => setWaiting(false) })

    return (
        <>
            <DataModal
                bodyHeight={280}
                title='Alteração de senha'
                open={open}
                sizeModal='sm'
                waiting={waiting}
                textSubmitButton='Confirmar'
                onClickSubmitButton={handleClickSubmitButton}
                onClickCancelButton={handleClickCancelButton}
            >

                <Form.Group controlId="password">
                    <Form.ControlLabel>Senha atual</Form.ControlLabel>
                    <InputGroup inside>
                        <Form.Control name="password"
                            autoComplete="off"
                            type={visible1 ? 'text' : 'password'}
                            value={password}
                            onChange={setPassword}
                        />
                        <InputGroup.Button onClick={handleVisible1}>
                            {visible1 ? <EyeIcon /> : <EyeSlashIcon />}
                        </InputGroup.Button>
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="newPassword">
                    <Form.ControlLabel>Nova senha</Form.ControlLabel>
                    <InputGroup inside>
                        <Form.Control name="newPassword"
                            autoComplete="off"
                            type={visible2 ? 'text' : 'password'}
                            value={newPassword}
                            onChange={setNewPassword}
                        />
                        <InputGroup.Button onClick={handleVisible2}>
                            {visible2 ? <EyeIcon /> : <EyeSlashIcon />}
                        </InputGroup.Button>
                    </InputGroup>
                    <Form.ErrorMessage show={error === 1}>Senha não pode ser vazia</Form.ErrorMessage>
                    <Form.ErrorMessage show={error === 2}>Senha não confere com a confirmação</Form.ErrorMessage>
                </Form.Group>

                <Form.Group controlId="confirmPassword">
                    <Form.ControlLabel>Confirmação da nova senha</Form.ControlLabel>
                    <InputGroup inside>
                        <Form.Control name="confirmPassword"
                            autoComplete="off"
                            type={visible3 ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={setConfirmPassword}
                        />
                        <InputGroup.Button onClick={handleVisible3}>
                            {visible3 ? <EyeIcon /> : <EyeSlashIcon />}
                        </InputGroup.Button>
                    </InputGroup>
                    <Form.ErrorMessage show={error === 2}>Senha não confere com a confirmação</Form.ErrorMessage>
                </Form.Group>

            </DataModal>

            <Confirm
                open={openConfirm}
                message={`Confirma a alteração da senha?`}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYesButton}
            />
        </>
    )
}

export default AlterarSenha
