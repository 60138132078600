import { FlexboxGrid } from "rsuite"

import DadosField from "./DadosField"

const DadosCadastro = ({ dados }) => {
    const styleSitCadastral = {
        color: '#fff',
        backgroundColor: dados.ativa === undefined
            ? 'transparent'
            : dados.ativa
                ? '#4fbb5e'
                : '#f04f43',
        fontWeight: 'bold'
    }

    const styleSitEspecial = {
        color: '#555',
        backgroundColor: '#f7edbb'
    }

    return (
        <FlexboxGrid justify="start" align="bottom">
            <DadosField
                label={'Situação'}
                value={dados.situacao}
                style={styleSitCadastral}
                size={{ lg: dados.ativa === undefined || dados.ativa ? 24 : 20 }}
            />
            {dados.ativa === undefined || dados.ativa
                ? <></>
                : <DadosField
                    label={'Data da Situação'}
                    value={dados.data_situacao}
                    style={styleSitCadastral}
                    size={{ lg: 4 }}
                />
            }

            {dados.situacao_especial &&
                <>
                    <DadosField label={'Situação Especial'} value={dados.situacao_especial} style={styleSitEspecial} size={{ lg: 20 }} />
                    <DadosField label={'Data da Situação Especial'} value={dados.data_situacao_especial} style={styleSitEspecial} size={{ lg: 4 }} />
                </>
            }

            <DadosField label={'Natureza Jurídica'} value={dados.natureza_juridica} size={{ lg: 17 }} />
            <DadosField label={'Porte'} value={dados.porte} size={{ lg: 4 }} />
            <DadosField label={'Tipo'} value={dados.tipo} size={{ lg: 3 }} />

            <DadosField label={'Endereço'} value={dados.endereco} size={{ lg: 16 }} />
            <DadosField label={'Número'} value={dados.numero} size={{ lg: 3 }} />
            <DadosField label={'Complemento'} value={dados.complemento} size={{ lg: 5 }} />

            <DadosField label={'Bairro'} value={dados.bairro} size={{ lg: 9 }} />
            <DadosField label={'CEP'} value={dados.cep} size={{ lg: 4 }} />
            <DadosField label={'Município (Cód. IBGE)'} value={dados.municipio} size={{ lg: 9 }} />
            <DadosField label={'UF'} value={dados.uf} size={{ lg: 2 }} />

            {(dados.nome_cidade_no_exterior || dados.pais) &&
                <DadosField
                    label={'Cidade no exterior / País'}
                    value={dados.nome_cidade_no_exterior
                        + (dados.nome_cidade_no_exterior && dados.pais ? ' - ' : '')
                        + dados.pais
                    }
                />
            }

            <DadosField label={'e-mail'} value={dados.email} size={{ lg: 12 }} />
            <DadosField label={'Telefone 1'} value={dados.telefone_1} size={{ lg: 4 }} />
            <DadosField label={'Telefone 2'} value={dados.telefone_2} size={{ lg: 4 }} />
            <DadosField label={'Fax'} value={dados.fax} size={{ lg: 4 }} />

            <DadosField label={'Início Atividades'} value={dados.data_inicio_atividade} size={{ lg: 4 }} />
            <DadosField label={'Capital Social'} value={dados.capital_social} size={{ lg: 4 }} />
            <DadosField label={'Qualificação do Responsável'} value={dados.qualificacao_responsavel} size={{ lg: 16 }} />

            {dados.ente_federativo_responsavel &&
                <DadosField label={'Ente Federativo Responsável'} value={dados.ente_federativo_responsavel} />
            }

            <DadosField label={'Opção pelo MEI'} value={dados.opcao_pelo_mei} size={{ lg: 4 }} />

            {dados.opcao_pelo_mei === "S" || dados.data_opcao_pelo_mei || dados.data_exclusao_do_mei
                ? <>
                    <DadosField label={'Data Opção'} value={dados.data_opcao_pelo_mei} size={{ lg: 4 }} />
                    <DadosField label={'Data Exclusão'} value={dados.data_exclusao_do_mei} size={{ lg: 4 }} />
                </>
                : <></>
            }

            <DadosField label={'Opção pelo Simples'} value={dados.opcao_pelo_simples} size={{ lg: 4 }} />

            {dados.opcao_pelo_simples === "S" || dados.data_opcao_pelo_simples || dados.data_exclusao_do_simpes
                ? <>
                    <DadosField label={'Data Opção'} value={dados.data_opcao_pelo_simples} size={{ lg: 4 }} />
                    <DadosField label={'Data Exclusão'} value={dados.data_exclusao_do_simples} size={{ lg: 4 }} />
                </>
                : <></>
            }
        </FlexboxGrid>
    )
}

export default DadosCadastro