import { useContext } from "react";
import { Container, CustomProvider, Loader, Panel, Stack } from "rsuite";
import { AuthContext } from "../contexts/authContext";

const Loading = ({ text }) => {

    const { darkMode } = useContext(AuthContext)

    return (
        <CustomProvider theme={darkMode ? 'dark' : 'light'}>
            <Container style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Panel bordered shaded className="panel-data">
                    <Stack direction='row' alignItems="center" spacing={30}>
                        <Loader size='md' />
                        <h6>{text || 'Carregando ...'}</h6>
                    </Stack>
                </Panel>
            </Container>
        </CustomProvider>
    )
}

export default Loading
