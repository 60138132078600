import { useState } from 'react';
import { EmpresaContext } from './empresaContext';

export const EmpresaProvider = ({ children }) => {

    const [saldoCredito, setSaldoCredito] = useState(0);
    const [empresaId, setEmpresaId] = useState();
    const [empresaReadOnly, setEmpresaReadOnly] = useState(false);
    const [permiteTelaSelecaoLeads, setPermiteTelaSelecaoLeads] = useState(false);
    const [permiteApiPropriaLeads, setPermiteApiPropriaLeads] = useState(false);

    return (
        <EmpresaContext.Provider value={{
            empresaId, saldoCredito, empresaReadOnly, permiteTelaSelecaoLeads, permiteApiPropriaLeads,
            setEmpresaId, setSaldoCredito, setEmpresaReadOnly, setPermiteTelaSelecaoLeads, setPermiteApiPropriaLeads
        }}>
            {children}
        </EmpresaContext.Provider>
    );
};