import api from '../services/api.js';

const resource = '/usuarios'

const getUsuarios = async ({ idEmpresa, pageNumber, itemsPerPage, sortColumn, sortType, searchText }) => {
    const response = await api.get(resource, {
        params: {
            idEmpresa,
            pageNumber,
            itemsPerPage,
            sortColumn,
            sortType,
            searchText
        }
    })
    return response.data;
}

const getUsuario = async id => {
    const response = await api.get(`${resource}/${id}`)
    return response.data;
}

const saveUsuario = usuario => {
    if (!usuario.idUsuario) {
        return api.post(resource, usuario)
    } else {
        return api.patch(`${resource}/${usuario.idUsuario}`, usuario)
    }
}

const deleteUsuario = id => api.delete(`${resource}/${id}`)

export const useApiUsuario = () => ({
    getUsuarios,
    getUsuario,
    saveUsuario,
    deleteUsuario
})